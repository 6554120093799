/* footer */
footer {
    position: relative;
    background: url(../img/img-18.jpg) no-repeat;
    background-size: cover;
    line-height: 1.2;
    padding-top: 50px;
    font-size: 16px;
    color: #2e2e2e;
    .container {
        display: flex;
        justify-content: space-between;
    }
    ul {
        @include ul-default;
        li {
            margin: 12px 0;
            &.active {
                a {
                    color: #fe0000;
                }
            }
        }
    }
    a {
        color: #2e2e2e;
        &:focus {
            color: #2e2e2e;
        }
        &:hover {
            color: #fe0000;
        }
    }
    h5 {
        color: #2f2f2f;
        margin: 0 0 25px;
    }
    .logo img {
        max-width: 100%;
    }
    .footer__item {
        padding-right: 25px;
        &:last-of-type {
            padding-right: 0;
        }
        &.f--logo {
            text-align: center;
            p {
                font-family: $bold;
                font-size: 14px;
                margin: 10px 0;
            }
        }
        &.f--nav {
            ul li a span {
                display: block;
            }
        }
        .socials {
            display: flex;
            justify-content: center;
            margin: 20px 0;
            li {
                display: flex;
                align-items: center;
                position: relative;
                margin: 0 17px;
                &:after {
                    content: '';
                    width: 1px;
                    height: 22px;
                    background-color: #676767;
                    position: absolute;
                    top: 50%;
                    right: -18px;
                    transform: translateY(-50%);
                }
                &:last-of-type:after {
                    display: none;
                }
                a {
                    font-size: 20px;
                }
            }
        }
    }
    .col--location,
    .col--phone,
    .col--email, {
        padding-left: 0;
        &:after {
            color: #4e4e4e;
            left: -20px;
        }
    }
    .col--email:after {
        top: 3px;
    }
    .f--cts__list {
        margin-top: 25px;
    }
    .artmedia {
        display: flex;
        align-items: center;
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
    .down {
        background-color: #c70000;
        color: #fff;
        position: relative;
        overflow: hidden;
        margin-top: 35px;
        &:before {
            content: '';
            width: 50%;
            background-color: #fe0000;
            position: absolute;
            left: 60%;
            top: 0;
            bottom: 0;
            transform: skew(-30deg);
            z-index: 0;
        }
        .container {
            display: flex;
            height: 66px;
            align-items: center;
            justify-content: space-between;
            position: relative;
        }
        p {
            margin: 0;
        }
    }
    @media screen and (max-width: 1200px) {
        font-size: 14px;
        .col--location,
        .col--phone {
            &:after {
                top: 1px;
            }
        }
        .col--email:after {
            top: 1px;
        }
    }
    @media screen and (max-width: 1024px) {
        padding-top: 30px;
        h5 {
            margin-bottom: 15px;
        }
        .down {
            margin-top: 20px;
        }
        .col--location,
        .col--phone,
        .col--email {
            padding-left: 20px;
            &:after {
                left: 0;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .container {
            flex-wrap: wrap;
        }
        .footer__item {
            &.f--logo {
                width: 100%;
                padding-right: 0;
            }
        }
        .down:before {
            left: 70%;
        }
    }
    @media screen and (max-width: 700px) {
        .down .container {
            height: auto;
            flex-direction: column;
            justify-content: center;
            justify-content: center;
            text-align: center;
            padding: 15px;
        }
        .artmedia {
            margin-top: 10px;
        }
    }
    @media screen and (max-width: 600px) {
        h5 {
            margin-bottom: 10px;
        }
        .footer__item {
            width: 100%;
            padding-right: 0;
            margin-bottom: 15px;
            &.f--logo {
                margin-bottom: 0;
            }
        }
        ul li {
            margin: 7px 0;
        }
    }
}
/* footer */