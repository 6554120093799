/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

.preload {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 9999;
    &.load {
        opacity: 0;
        transition: 500ms;
    }
}

/* main slider */
.slider {
	margin-bottom: 0px!important;
	.slick-slide {
		overflow: hidden;
		position: relative;
		outline: none;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		.slider__block {
            width: 660px;
			position: absolute;
			left: 50%;
			right: 0;
			top: 50%;
			text-align: center;
			color: #fff;
            margin: 0 auto 0 -583px;
            transform: translateY(-50%);
            .slider__block--title {
                display: block;
                background-color: rgba(0,0,0,0.3);
                font-family: $bold;
                font-size: 48px;
                padding: 25px 30px;
            }
            .btn {
                font-family: $bold;
                text-transform: uppercase;
                margin: 40px auto 0;
            }
		}
	}
	.slick-dots {
		bottom: 30px;
		li {
			margin: 0px 2px;
			button {
				width: 15px;
				height: 15px;
				background-color: #efefef;
				border-radius: 100%;
				border: 1px solid #3361eb;
                transition: 300ms;
				&:before {
					display: none;
				}
				&:hover {
					background-color: #3361eb;
				}
			}
			&.slick-active {
				button {
					background-color: #3361eb;
				}
			}
		}
	}
    &.arrow {
        .slick-arrow {
            &.slick-prev {
                left: 45px;
            }
            &.slick-next {
                right: 45px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .slick-slide {
            .slider__block {
                left: 15%;
                margin: 0;
                .slider__block--title {
                    font-size: 40px;
                }
            }
        }
        &.arrow {
            .slick-arrow {
                &.slick-prev {
                    left: 30px;
                }
                &.slick-next {
                    right: 30px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .slick-slide {
            height: 380px;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center center;
            }
            .slider__block {
                width: 600px;
                left: 0;
                right: 0;
                margin: 0 auto;
                .slider__block--title {
                    font-size: 30px;
                    padding: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-slide {
            height: 300px;
            .slider__block {
                width: auto;
                left: 75px;
                right: 75px;
                .slider__block--title {
                    display: table;
                    font-size: 24px;
                    padding: 15px 25px;
                    margin: 0 auto;
                }
                .btn {
                    margin-top: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            height: 275px;
            .slider__block .slider__block--title {
                font-size: 20px;
            }
        }
        &.arrow {
            .slick-arrow {
                &.slick-prev {
                    left: 15px;
                }
                &.slick-next {
                    right: 15px;
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .slick-slide {
            height: 220px;
            .slider__block {
                line-height: 1.1;
                left: 50px;
                right: 50px;
                .slider__block--title {
                    font-size: 18px;
                    padding: 15px 20px;
                }
                .btn {
                    margin: 15px auto 0;
                }
            }
        }
        &.arrow {
            .slick-arrow {
                &.slick-prev {
                    left: 7px;
                }
                &.slick-next {
                    right: 7px;
                }
            }
        }
    }
}
.arrow {
    .slick-arrow {
        width: 80px;
        height: 80px;
        border: 3px solid #f9f9fb;
        border-radius: 100%;
        z-index: 1;
        transition: 300ms;
        &:before {
            display: none;
        }
        &:after {
            content: '';
            background: url(../img/arrowPrev.png) no-repeat center center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &.slick-prev {
            left: 15px;
        }
        &.slick-next {
            right: 15px;
            &:after {
                transform: rotateY(180deg);
            }
        }
        &:hover {
            opacity:  0.7;
        }
    }
    @media screen and (max-width: 1440px) {
        .slick-arrow {
            width: 60px;
            height: 60px;
            &:after {
                background-size: 18px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .slick-arrow {
            width: 50px;
            height: 50px;
            &:after {
                background-size: 15px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-arrow {
            width: 40px;
            height: 40px;
            border: 2px solid #fff;
            &:after {
                background-size: 11px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .slick-arrow {
            width: 35px;
            height: 35px;
            &:after {
                background-size: 8px;
            }
        }
    }
}
/* main slider */

/* main about */
.about {
    display: flex;
    flex-wrap: wrap;
    .left {
        width: 47%;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(87,87,87,0.7) url(../img/bg.png) no-repeat center center;
            background-size: cover;
        }
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            filter: grayscale(100%);
        }
    }
    .right {
        width: 53%;
        padding: 100px 0 100px 125px;
    }
    .about__img {
        width: 425px;
        position: absolute;
        top: 50%;
        right: -50px;
        transform: translateY(-50%); 
        z-index: 1;
        box-shadow: 5px 5px 10px rgba(0,0,0,.4);
    }
    .about__block {
        max-width: 500px;
    }
    .about__works {
        display: flex;
        justify-content: space-between;
        line-height: 1;
        margin-top: 35px;
        .about__works--col {
            text-align: center;
            span {
                display: block;
            }
            .gray {
                font-family: $bold;
            }
            .num {
                font-family: $bold;
                font-size: 36px;
                color: #f00000;
            }
        }
    }
    h1 {
        color: #6c6c6c;
    }
    @media screen and (max-width: 1200px) {
        .right {
            padding: 50px 15px 50px 75px;
        }
        .about__works .about__works--col .num {
            font-size: 32px;
        }
        .about__img {
            right: -35px;
        }
    }
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        .left {
            width: 100%;
            order: 2;
            > img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .right {
            width: 100%;
            order: 1;
            padding: 30px 15px;
        }
        .about__block {
            max-width: 100%;
        }
        .about__works {
            justify-content: center;
            margin-top: 15px;
            .about__works--col {
                margin: 15px 15px 0;
            }
        }
        .about__img {
            display: flex;
            position: relative;
            justify-content: center;
            left: 0;
            top: 0;
            transform: translate(0,0);
            margin: 35px auto;
            img {
                max-width: 100%;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .about__img {
            width: calc(100% - 30px);
            margin: 35px 15px;
            img {
                width: 100%;
                height: auto;
            }
        }
        .about__works {
            .about__works--col {
                margin: 0 10px;
                .num {
                    font-size: 24px;
                    margin: 5px 0;
                }
            }
        }
    }
    @media screen and (max-width: 380px) {
        .about__works {
            .about__works--col {
                &:first-of-type {
                    margin-left: 0;
                }
                &:last-of-type {
                    margin-right: 0;
                }
                .num {
                    font-size: 20px;
                }
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}
/* main about */

/* main catalog */
.cat {
    padding-top: 100px;
    overflow: hidden;
    @media screen and (max-width: 1440px) {
        padding-top: 75px;
    }
    @media screen and (max-width: 1024px) {
        padding-top: 50px;
    }
    @media screen and (max-width: 768px) {
        padding-top: 30px;
    }
}
.cat__block {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 8px;
    margin: 0 -10px;
    .cat__item {
        height: 445px;
        width: calc(33.33% - 20px);
        overflow: hidden;
        margin: 0 10px 20px;
        position: relative;
        &:nth-last-of-type(-n+3) {
            margin-bottom: 0;
        }
        &:before {
            content: '';
            position: absolute;
            top: 22px;
            left: 22px;
            right: 22px;
            bottom: 22px;
            border: 2px solid #f0f0f0;
            transition: 300ms;
            pointer-events: none;
            opacity: 0;
            z-index: 1;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.3);
            pointer-events: none;
            transition: 300ms;
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: 300ms;
        }
        .cat__title {
            font-family: $bold;
            font-size: 30px;
            color: #ffffff;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 30px;
            right: 30px;
            transform: translateY(-50%);
            line-height: 1.2;
            z-index: 1;
            span {
                display: block;
                text-transform: uppercase;
            }
        }
        &:hover {
            &:before {
                opacity: 1;
            }
            &:after {
                background-color: rgba(0,0,0,0.5);
            }
            img {
                transform: scale(1.1);
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .cat__item {
            height: 400px;
            .cat__title {
                font-size: 26px;
            }
        }
    }
    @media screen and (max-width: 1366px) {
        .cat__item {
            height: 375px;
        }
    }
    @media screen and (max-width: 1200px) {
        .cat__item {
            height: 330px;
            &:before {
                top: 18px;
                left: 18px;
                right: 18px;
                bottom: 18px;
                opacity: 1;
            }
            &:after {
                background-color: rgba(0,0,0,.5);
            }
            .cat__title {
                font-size: 22px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .cat__item {
            height: 280px;
            .cat__title {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .cat__item {
            width: calc(50% - 20px);
            &:before {
                left: 15px;
                right: 15px;
                bottom: 15px;
                top: 15px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        padding: 0 15px;
        margin: 0;
        .cat__item {
            width: 100%;
            margin: 0 0 20px!important;
        }
    }
}
/* main catalog */

/* main partners */
.partners {
    padding: 90px 0 0;
    @media screen and (max-width: 1440px) {
        padding-top: 75px;
    }
    @media screen and (max-width: 1200px) {
        padding-top: 50px;
    }
    @media screen and (max-width: 1024px) {
        padding-top: 30px;
    }
}
.partners__slider {
    .slick-list {
        padding: 0 7%!important;
    }

    .slick-slide {
        display: flex;
        height: 150px;
        align-items: center;
        justify-content: center;
        outline: none;
        margin: 0 30px;
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            object-position: center center;
            transition-duration: 300ms!important;
            opacity: 0.3;
        }
    }
    .slick-track {
        margin: 0 auto;
        .slick-slide {
            &.slick-current {
                img {
                    opacity: 1;
                }
                & + .slick-active {
                    img {
                        opacity: 1;
                    }
                }
                & + .slick-active + .slick-active {
                    img {
                        opacity: 1;
                    }
                }
                & + .slick-active + .slick-active + .slick-active img {
                    opacity: 0.3;
                }
            }
            &.slick-active + .slick-active img {
                opacity: 1;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .slick-slide {
            margin: 0 30px;
        }
        .slick-track {
            .slick-slide {
                &.slick-current {
                    & + .slick-active + .slick-active  img {
                        opacity: 0.3;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .slick-list {
            padding: 0!important;
        }
        .slick-slide {
            img {
                opacity: 1!important;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            height: 120px;
            margin: 0 20px;
        }
    }
}
/* main partners */

/* main news */
.news {
    .container {
        padding-top: 75px;
    }
    @media screen and (max-width: 1024px) {
        .container {
            padding-top: 50px;
        }
    }
    @media screen and (max-width: 768px) {
        .container {
            padding-top: 25px;
        }
    }
}
.news__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .news__item {
        display: flex;
        flex-direction: column;
        width: calc(50% - 15px);
        background-color: #c70000;
        color: #fff;
        .news__img {
            height: 255px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                transition-duration: 300ms;
            }
        }
        .news__info {
            flex: 1 0 auto;
            position: relative;
            padding: 18px 27px 20px;
            overflow: hidden;
            z-index: 1;
            &:before {
                content: '';
                width: 50%;
                background-color: #fe0000;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 74%;
                z-index: -1;
                transform: skew(-37deg);
                transition: 300ms;
            }
            .date {
                display: block;
                margin-bottom: 15px;
            }
            p {
                margin: 0;
            }
        }
        &:hover {
            .news__img img {
                transform: scale(1.1);
            }
            .news__info:before {
                left: 85%;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .news__item {
            .news__img {
                height: 200px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .news__item {
            .news__img {
                height: 150px;
            }
            .news__info {
                padding: 15px 20px 15px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .news__item {
            width: calc(50% - 10px);
        }
    }
    @media screen and (max-width: 500px) {
        .news__item {
            width: 100%;
            margin-bottom: 20px;
            &:last-of-type {
                margin-bottom: 0;
            }
            .news__img {
                height: auto;
                img {
                    height: auto;
                }
            }
            .news__info {
                .date {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
/* main news */

/* main form */
.form {
    position: relative;
    &:before {
        content: '';
        background: url(../img/bg-1.png) no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        padding: 75px 15px;
    }
    h2 {
        width: 100%;
        color: #fff;
    }
    input[type="text"],
    input[type="email"] {
        width: calc(50% - 140px);
        margin-bottom: 0;
    }
    .btn {
        width: 250px!important;
    }
    @media screen and (max-width: 1024px) {
        .container {
            padding: 50px 15px;
        }
    }
    @media screen and (max-width: 768px) {
        .container {
            padding: 35px 15px;
        }
    }
    @media screen and (max-width: 680px) {
        input[type="text"],
        input[type="email"] {
            width: 100%;
            margin-bottom: 15px;
        }
        .btn {
            margin: 0 auto;
        }
    }
}
/* main form */

/* main content */