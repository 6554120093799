/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
    font-family: 'robotoblack';
    src: url('../fonts/roboto-black-webfont.eot');
    src: url('../fonts/roboto-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-black-webfont.woff2') format('woff2'),
         url('../fonts/roboto-black-webfont.woff') format('woff'),
         url('../fonts/roboto-black-webfont.ttf') format('truetype'),
         url('../fonts/roboto-black-webfont.svg#robotoblack') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotobold';
    src: url('../fonts/roboto-bold-webfont.eot');
    src: url('../fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('../fonts/roboto-bold-webfont.woff') format('woff'),
         url('../fonts/roboto-bold-webfont.ttf') format('truetype'),
         url('../fonts/roboto-bold-webfont.svg#robotobold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotoitalic';
    src: url('../fonts/roboto-italic-webfont.eot');
    src: url('../fonts/roboto-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-italic-webfont.woff2') format('woff2'),
         url('../fonts/roboto-italic-webfont.woff') format('woff'),
         url('../fonts/roboto-italic-webfont.ttf') format('truetype'),
         url('../fonts/roboto-italic-webfont.svg#robotoitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotolight';
    src: url('../fonts/roboto-light-webfont.eot');
    src: url('../fonts/roboto-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-light-webfont.woff2') format('woff2'),
         url('../fonts/roboto-light-webfont.woff') format('woff'),
         url('../fonts/roboto-light-webfont.ttf') format('truetype'),
         url('../fonts/roboto-light-webfont.svg#robotolight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotomedium';
    src: url('../fonts/roboto-medium-webfont.eot');
    src: url('../fonts/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-medium-webfont.woff2') format('woff2'),
         url('../fonts/roboto-medium-webfont.woff') format('woff'),
         url('../fonts/roboto-medium-webfont.ttf') format('truetype'),
         url('../fonts/roboto-medium-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotoregular';
    src: url('../fonts/roboto-regular-webfont.eot');
    src: url('../fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto-regular-webfont.woff') format('woff'),
         url('../fonts/roboto-regular-webfont.ttf') format('truetype'),
         url('../fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

$regular: 'robotoregular';
$bold: 'robotobold';
$medium: 'robotomedium';
$light: 'robotolight';
$italic: 'robotoitalic';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
    min-width: 300px;
    color: #353535;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	-webkit-text-size-adjust: 100%;
    padding-top: 159px;
	margin: 0;
    @media screen and (max-width: 1024px) {
        padding-top: 77px;
    }
    @media screen and (max-width: 768px) {
        display: block;
    }
}

h1 {
	font-family: $bold;
	font-size: 36px;
    font-weight: normal;
	text-transform: uppercase;
	color: #373737;
	line-height: 1;
	margin: 0 0 40px;
	@media screen and (max-width: 1200px) {
		font-size: 32px;
        margin-bottom: 30px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 28px;
        margin-bottom: 20px;
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
    }
}
h2 {
    font-weight: normal;
	font-family: $bold;
	font-size: 26px;
	color: #353535;
    text-transform: uppercase;
    margin: 0 0 40px;
	@media screen and (max-width: 1200px) {
		font-size: 30px;
        margin-bottom: 30px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 24px;
        margin-bottom: 20px;
	}
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}
h3 {
    font-weight: normal;
	font-family: $bold;
	font-size: 24px;
	color: #353535;
	@media screen and (max-width: 1200px) {
		font-size: 22px;
	}
    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}
h4 {
    font-weight: normal;
	font-family: $bold;
	font-size: 22px;
	color: #353535;
    @media screen and (max-width: 1200px) {
        font-size: 20px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 18px;
    }
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
}
h5 {
    font-weight: normal;
	font-family: $bold;
	font-size: 18px;
	color: #353535;
    @media screen and (max-width: 1024px) {
        font-size: 16px;
    }
}
h6 {
    font-weight: normal;
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #353535;
    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }
}
p {
    margin: 0 0 15px;
}
a {
    color: #353535;
	transition: 300ms;
	text-decoration: none;
	&:focus {
		color: #353535;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #f00000;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.main, .page {
	position: relative;
	flex: 1 0 auto;
    font-size: 18px;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        min-height: 50px;
        width: 100%;
		background-color: #eeeeee;
		font-family: $regular;
		font-size: 16px;
		padding: 14px 18px;
        margin-bottom: 15px;
        box-shadow: none;
        border: none;
        &::placeholder {
            color: #888888;
        }
	}
    h2 {
        text-align: center;
    }
    @media screen and (max-width: 1200px) {
        font-size: 16px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            min-height: 40px;
            font-size: 14px;
            padding: 10px 15px;
        }
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            min-height: 35px;
            padding: 8px 15px;
        }
    }
}
.main {
	.container {
		padding-top: 100px;
		padding-bottom: 100px;
        @media screen and (max-width: 1200px) {
            padding-top: 75px;
            padding-bottom: 75px;
        }
		@media screen and (max-width: 1024px) {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}
}

.container {
    width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
    @media screen and (max-width: 1200px) {
        width: auto;
    }
}

.close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 25px;
        height: 2px;
        background-color: #f00000;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
    @media screen and (max-width: 1024px) {
        width: 20px;
        height: 20px;
        &:before, &:after {
            width: 100%;
        }
    }
}
.btn {
	display: table;
    height: 50px;
	width: auto!important;
	background-color: #f00000!important;
	font-family: $bold!important;
	font-size: 18px;
	color: #fefefe!important;
    text-align: center;
	cursor: pointer;
	border-radius: 0px;
	border: none;
	padding: 15px 40px 14px!important;
    line-height: 1;
	transition: 300ms;
	&:focus, &:active {
		background-color: #f00000!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #c70000!important;
		color: #fff!important;
	}	
    @media screen and (max-width: 1200px) {
        height: 40px;
        font-size: 16px;
        padding: 11px 30px 10px!important;
    }
    @media screen and (max-width: 1024px) {
        height: 35px;
        font-size: 14px;
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
}
input.btn {
    padding-top: 14px!important;
    @media screen and (max-width: 1200px) {
        padding-top: 9px!important;
    }
   
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			left: 0;
			top: 4px;
		}
	}
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 20px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 16px;
		color: #f00000;
		position: absolute;
		top: 0px;
		left: 0;
	}
    @media screen and (max-width: 1200px) {
        &:after {
            font-size: 14px;
        }
    }
}
.col--location:after {
	content: '\f041';
}
.col--phone {
    &:after {
        content: '\f095';
    }
    a {
        pointer-events: none;
    }
    @media screen and (max-width: 1024px) {
        a {
            pointer-events: auto;
        }
    }
}
.col--email {
    &:after {
        content: '\f0e0';
        font-size: 13px;
        top: 2px;
    }
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    @media screen and (max-width: 1200px) {
        &:after {
            font-size: 12px;
        }
    }
}
.gray {
    color: #afafaf;
}
.bg--gray {
    background-color: #f0f0f0
}

/* header */
header {
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    font-size: 16px;
    line-height: 1;
    z-index: 9;
    box-shadow: 0px 0px 25px rgba(0,0,0,0.7);
    .up {
        background-color: #6c6c6c;
        color: #fff;
        a {
            color: #fff;
        }
        .container {
            display: flex;
            height: 36px;
            justify-content: flex-end;
            align-items: center;
            position: relative;
        }
        .close {
            display: none;
        }
    }
    > .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1px 15px;
    }
    .down {
        overflow: hidden;
        background-color: #f00000;
        position: relative;
        &:before {
            content: '';
            background-color: #c70000;
            width: 100%;
            height: 100%;
            position: absolute;
            right: 0;
            left: 50%;
            top: 0;
            transform: skew(-30deg) translateX(320px);
        }
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
        }
    }
    .col--location,
    .col--phone,
    .col--email {
        &:after {
            color: #fff;
        }
    }
    .col--location {
        flex: 1 0 auto;
    }
    ul {
        @include ul-default;
    }
    p {
        margin: 0;
    }

    .btn {
        height: 32px;
        font-family: $regular!important;
        font-size: 16px;
        text-transform: inherit;
        padding-top: 7px!important;
        padding-bottom: 6px!important;
    }

    /* header email */
    .col--email {
        display: flex;
        li {
            margin-right: 10px;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    /* header email */

	/* header phones */
	.phones {
        display: flex;
        margin-left: 20px;
        li {
            margin-right: 10px;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
	/* header phones */

	/* header logo */
	.logo {
        display: block;
        height: 75px;
        img {
            display: block;
            height: 100%;
        }
    }
	/* header logo */

    /* header search */
    .search {
        display: flex;
        width: 530px;
        align-items: center;
        border: 1px solid #c0c0c0;
        input {
            width: 100%;
            font-family: $regular;
            font-size: 14px;
            border: none;
            background-color: transparent;
            color: #2f2f2f;
            padding: 6px 16px;
            outline: none;
            &::placeholder {
                color: #919191;
            }
        }
        button {
            background-color: transparent;
            font-family: 'fontAwesome';
            font-size: 18px;
            color: #c6c6c6;
            border: none;
            padding: 0;
            margin: 0 8px;
            outline: none;
            cursor: pointer;
            &:after {
                content: '\f002';
            }
        }
    }
    /* header search */

	/* header nav */
	.nav {
        > ul {
            display: flex;
            > li {
                > a {
                    display: block;
                    font-size: 18px;
                    color: #fff;
                    padding: 14px 13px;
                    &:hover {
                        background-color: #c70000;
                    }
                }
                &.active > a {
                    background-color: #c70000;
                }
            }
        }
    }
	/* header nav */

    /* header socials */
    .socials {
        margin-right: 65px;
        ul {
            display: flex;
            li {
                position: relative;
                margin: 0 17px;
                &:after {
                    content: '';
                    width: 1px;
                    height: 22px;
                    background-color: #ff8484;
                    position: absolute;
                    right: -19px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:last-of-type:after {
                    display: none;
                }
                a {
                    font-size: 22px;
                    color: #fff;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
    /* header socials */

	/* header langs */
	.langs {
        font-size: 18px;
        color: #fff;
        position: absolute;
        right: 50%;
        bottom: 8px;
        padding-right: 20px;
        margin-right: -570px;
        transform: translateY(-50%);
        z-index: 1;
        .h--arr {
            display: block;
            position: absolute;
            right: 7px;
            top: 55%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 5px solid #ffffff;
        }
        .langs__title {
            display: block;
        }
        ul {
            display: none;
            background-color: rgba(240, 0,0,0.9);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 8px 12px;
            box-shadow: 0px 0px 10px rgba(255, 255,255, 0.8);
            li {
                margin: 5px 0;
                a {
                    color: #fff;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
	/* header langs */
	@media screen and (min-width: 1025px) {
        &.fix {
            position: fixed;
            .up {
                display: none;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        font-size: 14px;
        .langs {
            right: 15px;
            margin: 0;
        }
        .col--email:after {
            top: 1px;
        }
    }
	@media screen and (max-width: 1024px) {
        position: fixed;
        .socials {
            margin: 15px 0 10px;
            ul {
                justify-content: center;
                li {
                    &:after {
                        height: 20px;
                        background-color: #fff;
                    }
                    a {
                        font-size: 20px;
                    }
                }
            }
        }
        .langs {
            font-size: 16px;
            color: #f00000;
            bottom: auto;
            top: 38px;
            right: auto;
            left: 60px;
            .h--arr {
                border-top: 5px solid #353535;
            }
            .langs__title {
                color: #353535;
            }
            ul {
                padding: 5px 10px;
            }
        }
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 12px;
			top: 23px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #f00000;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
        .up {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 2;
            padding: 10px 20px;
            .container {
                display: block;
                height: auto;
                padding: 0;
            }
            .close {
                display: block;
                right: 5px;
                top: 5px;
            }
        }
        .col--location,
        .col--email,
        .col--phone {
            display: table;
            margin: 15px auto;
            li {
                margin: 0 0 5px;
            }
        }
        .col--location {
            margin: 10px auto;
        }
        .search {
            width: 100%;
            background-color: #fff;
            position: fixed;
            left: 0;
            right: 0;
            top: 77px;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
            transition: 300ms;
            &.open {
                opacity: 1;
                visibility: visible;
            }
        }
        .nav {
            display: none;
            position: fixed;
            background-color: #f00000;
            width: 100%;
            max-height: calc(100% - 77px);
            top: 77px;
            left: 0;
            right: 0;
            overflow: auto;
            padding: 15px 15px;
            > ul {
                display: block;
                width: 100%;
                > li {
                    > a {
                        font-size: 16px;
                        padding: 10px 15px;
                    }
                }
            }
        }
        > .container {
            justify-content: center;
        }
        .btn {
            display: block;
            width: 35px!important;
            height: 35px;
            position: fixed;
            bottom: 50px;
            right: 15px;
            border-radius: 100%;
            color: transparent!important;
            overflow: hidden;
            padding: 0!important;
            box-shadow: 0px 0px 10px rgba(255,255,255,0.7);
            &:hover {
                color: transparent!important;
            }
            &:after {
                content: '\f095';
                font-family: 'fontAwesome';
                font-size: 18px!important;
                color: #fff;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);
            }
        }
        .phones--btn {
            display: block;
            font-family: 'fontAwesome';
            font-size: 20px;
            color: #f00000;
            position: absolute;
            top: 28px;
            right: 15px;
            &:after {
                content: '\f095';
            }
            &:hover {
                text-decoration: none;
            }
        }

        .search--btn {
            position: absolute;
            top: 50%;
            right: 50px;
            font-family: 'fontAwesome';
            font-size: 18px;
            color: #f00000;
            text-decoration: none;
            transform: translateY(-50%);
            &:after {
                content: '\f002';
            }
        }

	}
    @media screen and (max-width: 768px) {
        .nav--btn {
            left: 5px;
        }
        .phones--btn {
            font-size: 18px;
            right: 10px;
            top: 30px;
        }
        .search--btn {
            right: 35px;
        }
        .langs {
            left: 45px;
            font-size: 14px;
            .langs__title {
                font-size: 14px;
            }
        }
        .btn {
            bottom: 25px;
        }
    }
    @media screen and (max-width: 360px) {
        .col--location {
            text-align: center;
        }
    }
}
/* header */