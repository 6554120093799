/* page content */
.page {
    padding-bottom: 80px;
	.news1, .news {
		.date {
			color: #fe0000;
		}
	}
	.page_nav {
		.active {
			color: #fe0000;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #fe0000;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    p a {
        color: #fe0000;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    h2 {
        text-align: left;
        text-transform: inherit;
        a {
            font-size: 16px;
            color: #fe0000;
        }
    }
    .page--block {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .left {
            width: calc(100% - 400px);
        }
        .right {
            max-width: 350px;
            img {
                max-width: 100%!important;
                width: auto!important;
                height: auto!important;
                float: none!important;
                margin: 0!important;
            }
        }
    }
	@media screen and (max-width: 1024px) {
        padding-bottom: 50px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
        .page--block {
            .left {
                width: calc(100% - 300px);
            }
            .right {
                width: 250px;
            }
        }
	}
    @media screen and (max-width: 768px) {
        padding-bottom: 30px;
        .page--block {
            .left {
                width: calc(100% - 250px);
            }
            .right {
                width: 200px;
            }
        }
    }
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
        .page--block {
            .left {
                width: 100%;
            }
            .right {
                margin: 15px 0;
                width: 100%;
            }
        }
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 16px;
    margin: 30px 0 50px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #fe0000;
			@include inline-block;
			a {
                color: #353535;
				text-decoration: none;
                &:hover {
                    color: #f00000;
                }
            }
			&:after {
				content: '/';
				margin: 3px 2px 0px 7px;
				color: #353535;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
    @media screen and (max-width: 1200px) {
        font-size: 14px;
        margin: 20px 0 40px;
    }
    @media screen and (max-width: 1024px) {
        padding-top: 10px;
        margin: 0px 0 25px;
        line-height: 1;
        ul li:after {
            margin-top: 0;
        }
    }
    @media screen and (max-width: 768px) {
        font-size: 12px;
        margin-bottom: 20px;
        ul li {
            &:after {
                margin: 0px 2px 0 5px;
            }
        }
    }
}
/* breadcrumbs */

/* page about */
.page .about {
    .right {
        padding-top: 0;
        padding-bottom: 0;
    }
    @media screen and (max-width: 380px) {
        .right {
            margin-bottom: 20px;
        }
    }
}
/* page about */

/* page сatalog */
.page .cat {
    padding-top: 0;
}
.cat--inside {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .filter {
        width: 100%;
    }
    .left {
        width: 325px;
    }
    .right {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 325px);
    }
    .filter {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        position: absolute;
        top: -75px;
        right: 0;
        z-index: 1;
        .filter__col {
            margin-left: 14px;
        }
    }
    .cat--inside__item {
        display: flex;
        flex-direction: column;
        width: calc(33.33% - 26px);
        background-color: #ffffff;
        color: #353535;
        margin: 0 0 26px 26px;
        .cat--inside__img {
            height: 240px;
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .cat--inside__info {
            flex: 1 0 auto;
            position: relative;
            padding: 25px 15px 45px;
            h5 {
                margin: 0 0 10px;
                transition: 300ms;
            }
            .btn {
                display: block;
                height: 38px;
                width: auto!important;
                text-transform: inherit;
                padding: 10px 20px 10px!important;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }
            .stiker {
                position: absolute;
                top: -40px;
                right: 15px;
            }
        }
        &:hover {
            .cat--inside__info h5 {
                color: #f00000;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .filter {
            top: -66px;
        }
        .cat--inside__item {
            .cat--inside__img {
                height: 210px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .filter {
            top: -51px;
        }
        .cat--inside__item {
            width: calc(50% - 26px);
            .cat--inside__info {
                padding-bottom: 40px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin: 0 -10px;
        .left {
            width: 100%;
            order: 2;
        }
        .right {
            width: 100%;
            order: 1;
        }
        .cat--inside__item {
            width: calc(33.33% - 20px);
            margin: 0 10px 20px;
            .cat--inside__info {
                padding-top: 15px;
                .stiker {
                    top: -65px;
                }
            }
        }
        .filter {
            justify-content: flex-start;
            top: 0;
            position: relative;
            .filter__col {
                margin: 0 0 15px 10px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .cat--inside__item {
            width: calc(50% - 20px);
            .cat--inside__img {
                height: 200px;
            }
        }
    }
    @media screen and (max-width: 420px) {
        margin: 0;
        .cat--inside__item {
            width: 100%;
            margin: 0 0 20px;
            .cat--inside__img {
                height: auto;
                img {
                    height: auto;
                }
            }
        }
        .filter .filter__col {
            margin-left: 0;
            margin-right: 10px;
        }
    }
}
.stiker {
    display: flex;
    width: 65px;
    height: 65px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ed1c24;
    border-radius: 100%;
    font-size: 9px;
    color: #fff;
    text-align: center;
    line-height: 1;
    .num {
        font-family: $bold;
        font-size: 24px;
    }
    .year {
        font-size: 10px;
        text-transform: uppercase;
    }
    @media screen and (max-width: 768px) {
        width: 55px;
        height: 55px;
        font-size: 8px;
        .num {
            font-size: 18px;
        }
    }
}
.sidebar {
    line-height: 1.1;
    * {
        user-select: none;
    }
    ul {
        @include ul-default;
        li {
            position: relative;
        }
    }
    .arr {
        display: flex;
        width: 40px;
        height: 55px;
        font-family: 'fontAwesome';
        font-size: 16px;
        color: #363636;
        position: absolute;
        top: 0;
        right: 0;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
        transition: 300ms;
        &:after {
            content: '\f107';
        }
    }
    .open > .arr {
        transform: rotate(-180deg);
    }
    > ul {
        width: 100%;
        overflow: hidden;
        > li {
            overflow: hidden;
            margin-bottom: 2px;
            > a {
                display: flex;
                flex-direction: column;
                min-height: 55px;
                justify-content: center;
                background-color: #fff;
                font-size: 18px;
                color: #252525;
                padding: 10px 35px 10px 58px;
                border-left: 6px solid #3e3e3e;
                position: relative;
                z-index: 2;
                img {
                    max-width: 14px;
                    max-height: 14px;
                    object-fit: contain;
                    object-position: center center;
                    position: absolute;
                    top: 50%;
                    left: 19px;
                    transform: translateY(-50%);
                    transition: 300ms;
                    filter: grayscale(100%);
                }
                span {
                    display: block;
                    font-size: 12px;
                    color: #a9a9a9;
                }
                &:hover {
                    img {
                        filter: grayscale(0);
                    }
                }
            }
            &.active > a {
                box-shadow: 0px 5px 7px rgba(0,0,0,0.5);
                img {
                    filter: grayscale(0);
                }
            }
            > ul {
                display: none;
                > li {
                    margin-bottom: 2px;
                    > a {
                        display: flex;
                        align-items: center;
                        min-height: 55px;
                        font-size: 18px;
                        background-color: #ed1c24;
                        color: #fff;
                        position: relative;
                        padding: 10px 35px 10px 26px;
                        z-index: 1;
                        &:hover {
                            opacity: 0.7;
                        }
                    }
                    > .arr {
                        color: #fff;
                    }
                    &.active > a {
                        box-shadow: 0px 5px 7px rgba(0,0,0,0.5);
                    }
                    ul {
                        display: none;
                        li {
                            margin-bottom: 1px;
                            &:last-of-type {
                                border-bottom: none;
                            }
                            a {
                                display: flex;
                                background-color: #fff;
                                align-items: center;
                                min-height: 50px;
                                padding: 10px 20px 10px 45px;
                                position: relative;
                                &:after {
                                    content: '';
                                    width: 5px;
                                    height: 5px;
                                    background-color: #353535;
                                    border-radius: 100%;
                                    position: absolute;
                                    top: 50%;
                                    left: 30px;
                                    transform: translateY(-50%);
                                }
                            }
                            &.active > a {
                                color: #f00000;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        font-size: 16px;
        > ul > li {
            > a {
                min-height: 50px;
                font-size: 16px;
                padding-left: 40px;
                img {
                    left: 15px;
                }
            }
            > ul > li {
                > a {
                    min-height: 50px;
                    font-size: 16px;
                }
                ul li a {
                    padding-left: 40px;
                    &:after {
                        left: 27px;
                    }
                }
            }
        }
        .arr {
            height: 50px;
        }
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
        margin: 0 10px;
        margin-top: 30px;
        > ul > li {
            > a {
                font-size: 14px;
                padding-left: 30px;
                border-left: 3px solid #3e3e3e;
                img {
                    max-width: 12px;
                    max-height: 12px;
                    left: 9px;
                }
            }
            > ul > li {
                > a {
                    font-size: 14px;
                }
            }
        }
    }
    @media screen and (max-width: 420px) {
        margin: 25px 0 0;
    }
}
.edd-root {
    width: auto;
    line-height: 1;
    &:after {
        display: none;
    }
    .edd-head {
        border: none;
        .edd-value {
            background-color: #fff;
            padding: 12px 50px 12px 25px;
        }
    }
    .edd-arrow {
        right: 22px;
    }
    .edd-option-selected {
        &:not(.edd-option-disabled) {
            color: #f00000;
        }
        &:before {
            display: none;
        }
    }
    .edd-option-focused:not(.edd-option-disabled) {
        color: #f00000;
    }
    .edd-option {
        padding: 5px 20px 5px 25px;
    }
    .edd-body {
        padding: 5px 0;
    }
    @media screen and (max-width: 1024px) {
        .edd-head {
            .edd-value {
                font-size: 14px;
                padding: 11px 35px 11px 15px;
            }
        }
        .edd-arrow {
            right: 12px;
        }
    }
}


.cat--unit {
    display: flex;
    flex-wrap: wrap;
    .left {
        position: relative;
        width: calc(100% - 470px);
        h6 {
            text-transform: inherit;
            margin: 15px 0;
        }
        .stiker {
            width: 170px;
            height: 170px;   
            font-family: $bold;
            font-size: 16px;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 1;
            .num {
                font-size: 68px;
            }
            .year {
                font-size: 26px;
            }
        }
    }
    .right {
        width: 450px;
        position: relative;
        background-color: #fff;
        padding: 40px 30px 45px;
        margin-left: 20px;
        ul {
            @include ul-default;
            li {
                margin: 2px 0;
            }
        }
        .price {
            display: block;
            font-family: $bold;
            font-size: 36px;
            color: #f00000;
            line-height: 1;
            margin: 40px 0 30px;
            .price__title {
                display: block;
                font-family: $medium;
                font-size: 18px;
                color: #a1a1a1;
                margin-bottom: 10px;
            }
            sup {
                font-size: 20px;
            }
        }
        .btn {
            width: 300px!important;
        }
    }
    h1 {
        font-size: 26px;
        text-transform: inherit;
    }
    @media screen and (max-width: 1200px) {
        .left {
            width: calc(100% - 370px);
            .stiker {
                width: 130px;
                height: 130px;
                font-size: 14px;
                .num {
                    font-size: 50px;
                }
                .year {
                    font-size: 22px;
                }
            }
        }
        .right {
            width: 350px;
            padding: 30px 20px 35px;
            .price {
                font-size: 30px;
            }
        }
        h1 {
            font-size: 22px;
        }
    }
    @media screen and (max-width: 1024px) {
        h1 {
            font-size: 20px;
        }
        .left {
            .stiker {
                width: 110px;
                height: 110px;
                font-size: 12px;
                .num {
                    font-size: 40px;
                }
                .year {
                    font-size: 18px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .left {
            width: 100%;
            .stiker {
                width: 80px;
                height: 80px;
                font-size: 10px;
                .num {
                    font-size: 30px;
                }
                .year {
                    font-size: 14px;
                }
            }
        }
        .right {
            width: 100%;
            padding: 20px 20px 25px;
            margin-left: 0;
            .price {
                font-size: 24px;
                margin: 20px 0 20px;
                .price__title {
                    font-size: 16px;
                    margin-bottom: 5px;
                }
            }
            .btn {
                width: auto!important;
                padding-left: 40px!important;
                padding-right: 40px!important;
            }
        }
    }
}
.cat--unit__for {
    .slick-slide {
        height: 390px; 
        outline: none;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        } 
    }
    @media screen and (max-width: 1024px) {
        .slick-slide {
            height: 320px;
        }
    }
    @media screen and (max-width: 480px) {
        .slick-slide {
            height: 270px;
        }
    }
    @media screen and (max-width: 380px) {
        .slick-slide {
            height: 230px;
        }
    }
}
.cat--unit__nav {
    margin: 0 -10px;
    .slick-slide {
        height: 124px;
        outline: none;
        margin: 0 10px;
        position: relative;
        cursor: pointer;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 3px solid #f00000;
            transition: 300ms;
            opacity: 0;
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        span {
            display: block;
            background-color: rgba(0,0,0,0.3);
            padding: 10px 5px;
            font-size: 16px;
            color: #fff;
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            line-height: 1;
        }
        &:hover, &.slick-current {
            &:after {
                opacity: 1;
            }
        }
    }
    .slick-arrow {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f00000;
        border-radius: 100%;
        line-height: 1;
        overflow: hidden;
        transition: 300ms;
        z-index: 1;
        &:before {
            display: none;
        }
        &:after {
            content: '';
            font-family: 'fontAwesome';
            font-size: 20px;
            color: #fff;
            line-height: 12px;
        }
        &.slick-prev {
            left: -5px;
            &:after {
                content: '\f104';
            }
        }
        &.slick-next {
            right: -5px;
            &:after {
                content: '\f105';
            }
        }
        &:hover {
            opacity: 0.7;
        }
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
        .slick-arrow {
            &.slick-prev {
                left: 15px;
            }
            &.slick-next {
                right: 15px;
            }
        }
        .slick-slide {
            span {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            span {
                font-size: 12px;
            }
        }
    }
    @media screen and (max-width: 680px) {
        margin: 0 -5px 20px;
        .slick-slide {
            margin: 0 5px;
        }
        .slick-arrow {
            &.slick-prev {
                left: 10px;
            }
            &.slick-next {
                right: 10px;
            }
        }
    }
    @media screen and (max-width: 400px) {
        .slick-slide {
            height: 100px;
        }
    }
    @media screen and (max-width: 340px) {
        .slick-slide {
            height: 90px;
        }
    }

}
.cat--unit__info {
    background-color: #fff;
    font-size: 16px;
    padding: 35px 48px;
    margin: 37px 0 75px;
    h5 {
        text-transform: inherit;
        margin: 0 0 25px;
    }
    @media screen and (max-width: 1024px) {
        padding: 20px 25px;
        font-size: 14px;
        margin: 30px 0 50px;
        h5 {
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 768px) {
        padding: 20px;
        margin: 25px 0 40px;
        h5 {
            margin-bottom: 10px;
        }
    }
}
.sim {
    h2 {
        margin-bottom: 20px;
    }
}
.similar__slider {
    display: block;
    width: 100%;
    .slick-list {
        padding: 0 100px!important;
    }
    .slick-track {
        display: flex;
    }
    .slick-slide {
        height: auto;
    }
    .cat--inside__item {
        margin-bottom: 0;
        font-size: 16px;
    }
    &.arrow {
        .slick-arrow {
            width: 62px;
            height: 62px;
            background-color: rgba(0,0,0,0.7);
            &:after {
                background: url(../img/simArr.png) no-repeat center center;
                background-size: 15px;
            }
            &.slick-prev {
                left: 35px;
            }
            &.slick-next {
                right: 35px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 0 5px;
        .slick-list {
            padding: 0!important;
        }
        .slick-slide {
            margin: 0 10px;
        }
        .cat--inside__item {
            font-size: 14px;
        }
        &.arrow {
            .slick-arrow {
                width: 50px;
                height: 50px;
                &:after {
                    background-size: 10px;
                }
                &.slick-prev {
                    left: 20px;
                }
                &.slick-next {
                    right: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        &.arrow {
            .slick-arrow {
                width: 40px;
                height: 40px;
            }
        }
        &.cat--inside {
            margin: 0;
        }
    }
    @media screen and (max-width: 420px) {
        .cat--inside__item {
            .cat--inside__img {
                height: 250px;
            }
            .cat--inside__info {
                .stiker {
                    right: 55px;
                }
            }
        }
    }
}
/* page сatalog */

/* page contacts */
.cts {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;
    ul {
        @include ul-default;
        li {
            margin: 3px 0;
        }
    }
    .cts__item {
        display: flex;
        flex-direction: column;
        width: calc(100% - 20px);
        justify-content: center;
        background-color: #eeeeee;
        padding: 22px;
        margin: 0 10px 20px;
        .col--location {
            padding-left: 43px;
            margin: 0;
            &:after {
                font-size: 30px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .col--phone {
            padding-left: 37px;
            &:after {
                font-size: 19px;
                top: 3px;
            }
        }
        .col--email {
            padding-left: 37px;
            &:after {
                font-size: 16px;
                top: 3px;
            }
        }
        h5 {
            margin: 0;
        }
        &.user {
            position: relative;
            padding-left: 72px;
            &:after {
                content: '';
                width: 24px;
                height: 32px;
                background: url(../img/user.png) no-repeat center center;
                position: absolute;
                top: 50%;
                left: 25px;
                transform: translateY(-50%);
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .cts__item {
            padding: 15px;
            .col--location {
                padding-left: 30px;
                &:after {
                    font-size: 26px;
                }
            }
            .col--phone,
            .col--email {
                padding-left: 30px;
            }
            .col--phone:after {
                font-size: 16px;
            }
            .col--email:after {
                font-size: 14px;
            }
            &.user {
                padding-left: 50px;
                &:after {
                    width: 22px;
                    height: 28px;
                    background-size: contain;
                    left: 15px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        .cts__item {
            width: 100%;
            .col--location {
                padding-left: 25px;
                &:after {
                    font-size: 24px;
                }
            }
            .col--phone,
            .col--email {
                padding-left: 25px;
            }
            &.user {
                padding-left: 40px;
                &:after {
                    width: 20px;
                    height: 23px;
                    left: 10px;
                }
            }
        }
    }
}
.map {
    height: 330px; 
    margin-bottom: 40px;
    iframe {
        height: 100%;
        width: 100%;
    }
    @media screen and (max-width: 768px) {
        height: 280px;
    }
}
.cts__form {
    .cts__input {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        input {
            width: calc(33.33% - 20px);
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .g-recaptcha {
        @include inline-block;
    }
    .btn {
        @include inline-block;
        width: 300px!important;
        margin: 0px 0 0 30px;
    }
    @media screen and (max-width: 768px) {
        .btn {
            width: auto!important;
            padding-left: 40px!important;
            padding-right: 40px!important;
            float: right;
        }
    }
    @media screen and (max-width: 600px) {
        .cts__input {
            margin: 0;
            input {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    @media screen and (max-width: 480px) {
        padding-bottom: 130px;
        .g-recaptcha {
            float: right;
        }
        .btn {
            margin-top: 15px;
        }
    }
    @media screen and (max-width: 340px) {
        .g-recaptcha {
            transform: scale(0.9);
            transform-origin: 100% 50%;
        }
    }
}
/* page contacts */

/* page pagination */
.pagination {
    width: 100%;
    line-height: 1;
    margin-top: 25px;
    ul {
        @include ul-default;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        li {
            margin: 0 5px 5px;
            a {
                display: flex;
                width: 35px;
                height: 35px;
                align-items: center;
                justify-content: center;
                font-family: $bold;
                background-color: #f00000;
                color: #fff;
                position: relative;
                &:after {
                    font-family: 'fontAwesome';
                    font-size: 20px;
                }
                &:hover {
                    color: #fff;
                    background-color: #c70000;
                }
            }
            &.active a {
                background-color: #c70000;
            }
            &.prev {
                a {
                    &:after {
                        content: '\f104';
                    }
                }
            }
            &.next {
                a {
                    &:after {
                        content: '\f105';
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin-top: 15px;
        ul li {
            a {
                width: 30px;
                height: 30px;
            }
        }
    }
}
/* page pagination */

/* modal */
.modal {
    display: none;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    .modal__layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .modal__block {
        width: 420px;
        background-color: #fff;
        position: absolute;
        padding: 20px;
        left: 0;
        right: 0;
        top: 50%;
        margin: 0 auto;
        transform: translateY(-50%);
        h4 {
            font-size: 20px;
            text-align: center;
            padding: 0 10px;
            margin: 0;
        }
    }
    form {
        display: block;
        padding-bottom: 130px;
        margin-top: 15px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            min-height: 50px;
            width: 100%;
            background-color: #eeeeee;
            font-family: $regular;
            font-size: 16px;
            padding: 14px 18px;
            margin-bottom: 15px;
            box-shadow: none;
            border: none;
            &::placeholder {
                color: #888888;
            }
        }
        .edd-root {
            width: 100%;
            margin-bottom: 15px;
            .edd-head .edd-value {
                background-color: #eee;
                padding-top: 17px;
                padding-bottom: 17px;
                padding-left: 18px;
            }
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 1200px) {
        .modal__block {
            h4 {
                font-size: 18px;
            }
        }
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 40px;
                font-size: 14px;
                padding: 10px 15px;
            }
            .edd-root {
                .edd-head .edd-value {
                    padding-top: 12px;
                    padding-bottom: 12px;
                    padding-left: 15px;
                    padding-right: 40px;
                }
                .edd-arrow {
                    right: 15px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 35px;
                padding: 8px 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .modal__block {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .modal__block {
            width: auto;
            left: 10px;
            right: 10px;
        }
    }
    @media screen and (max-width: 380px) {
        form {
            .g-recaptcha {
                transform: scale(0.85);
                transform-origin: 100% 50%;
            }
        }
    }
}

.call--btn {
    position: fixed;
    bottom: 100px;
    right: 40px;
    display: table;
    background-color: #ffcc00;
    font-family: 'FontAwesome';
    border-radius: 100%;
    text-decoration: none!important;
    width: 80px;
    height: 80px;
    text-align: center;
    padding: 23px 5px 5px 5px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.7);
    box-sizing: border-box;
    outline: none!important;
    z-index: 1;
    &:hover {
        animation-name: phone;
        animation-iteration-count: 1;
        animation-duration: 0.7s;
    }
    i {
        display: block;
        font-size: 35px;
        color: #fff;
    }
    &:after, &:before  {
        content: '';
        width: 90%;
        height: 90%;
        background-color: transparent;
        border: 1px solid #ffcc00;
        position: absolute;
        top: 5%;
        left: 5%;
        border-radius: 100%;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
        animation-name: ring;
        outline: none;  
    }
    &:after {
        animation-delay: 0.6s;
    }
    &:before {
        animation-delay: 0.8s;
        border: 1px solid #d9af04;
    }
    @media screen and (max-width: 1440px) {
        margin-right: 0;
        right: 35px;
        bottom: 75px;
    }
    @media screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
        padding-top: 15px;
        right: 15px;
        bottom: 80px;
        i {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        bottom: 50px;
    }
}

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.1;
    transform: scale(1.7);
  }
}
@keyframes phone {
  20% {
   transform: rotate(-30deg); 
  }

  80% {
    transform: rotate(390deg)
  }

  100% {
    transform: rotate(360deg);
  }
}

/* modal */

/* page content */