@charset "UTF-8";
/* Connection Font Icons */
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format("woff2"), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal; }

/* Connection Font Icons */
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
  font-family: 'robotoblack';
  src: url("../fonts/roboto-black-webfont.eot");
  src: url("../fonts/roboto-black-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-black-webfont.woff2") format("woff2"), url("../fonts/roboto-black-webfont.woff") format("woff"), url("../fonts/roboto-black-webfont.ttf") format("truetype"), url("../fonts/roboto-black-webfont.svg#robotoblack") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'robotobold';
  src: url("../fonts/roboto-bold-webfont.eot");
  src: url("../fonts/roboto-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-bold-webfont.woff2") format("woff2"), url("../fonts/roboto-bold-webfont.woff") format("woff"), url("../fonts/roboto-bold-webfont.ttf") format("truetype"), url("../fonts/roboto-bold-webfont.svg#robotobold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'robotoitalic';
  src: url("../fonts/roboto-italic-webfont.eot");
  src: url("../fonts/roboto-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-italic-webfont.woff2") format("woff2"), url("../fonts/roboto-italic-webfont.woff") format("woff"), url("../fonts/roboto-italic-webfont.ttf") format("truetype"), url("../fonts/roboto-italic-webfont.svg#robotoitalic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'robotolight';
  src: url("../fonts/roboto-light-webfont.eot");
  src: url("../fonts/roboto-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-light-webfont.woff2") format("woff2"), url("../fonts/roboto-light-webfont.woff") format("woff"), url("../fonts/roboto-light-webfont.ttf") format("truetype"), url("../fonts/roboto-light-webfont.svg#robotolight") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'robotomedium';
  src: url("../fonts/roboto-medium-webfont.eot");
  src: url("../fonts/roboto-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-medium-webfont.woff2") format("woff2"), url("../fonts/roboto-medium-webfont.woff") format("woff"), url("../fonts/roboto-medium-webfont.ttf") format("truetype"), url("../fonts/roboto-medium-webfont.svg#robotomedium") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'robotoregular';
  src: url("../fonts/roboto-regular-webfont.eot");
  src: url("../fonts/roboto-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-regular-webfont.woff2") format("woff2"), url("../fonts/roboto-regular-webfont.woff") format("woff"), url("../fonts/roboto-regular-webfont.ttf") format("truetype"), url("../fonts/roboto-regular-webfont.svg#robotoregular") format("svg");
  font-weight: normal;
  font-style: normal; }

* {
  box-sizing: border-box; }

*:after, *:before {
  display: block;
  line-height: 1; }

body {
  display: flex;
  height: 100vh;
  min-width: 300px;
  color: #353535;
  position: relative;
  flex-direction: column;
  font-family: "robotoregular";
  -webkit-text-size-adjust: 100%;
  padding-top: 159px;
  margin: 0; }
  @media screen and (max-width: 1024px) {
    body {
      padding-top: 77px; } }
  @media screen and (max-width: 768px) {
    body {
      display: block; } }

h1 {
  font-family: "robotobold";
  font-size: 36px;
  font-weight: normal;
  text-transform: uppercase;
  color: #373737;
  line-height: 1;
  margin: 0 0 40px; }
  @media screen and (max-width: 1200px) {
    h1 {
      font-size: 32px;
      margin-bottom: 30px; } }
  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 28px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 22px; } }

h2 {
  font-weight: normal;
  font-family: "robotobold";
  font-size: 26px;
  color: #353535;
  text-transform: uppercase;
  margin: 0 0 40px; }
  @media screen and (max-width: 1200px) {
    h2 {
      font-size: 30px;
      margin-bottom: 30px; } }
  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 24px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 20px; } }

h3 {
  font-weight: normal;
  font-family: "robotobold";
  font-size: 24px;
  color: #353535; }
  @media screen and (max-width: 1200px) {
    h3 {
      font-size: 22px; } }
  @media screen and (max-width: 1024px) {
    h3 {
      font-size: 20px; } }
  @media screen and (max-width: 768px) {
    h3 {
      font-size: 18px; } }

h4 {
  font-weight: normal;
  font-family: "robotobold";
  font-size: 22px;
  color: #353535; }
  @media screen and (max-width: 1200px) {
    h4 {
      font-size: 20px; } }
  @media screen and (max-width: 1024px) {
    h4 {
      font-size: 18px; } }
  @media screen and (max-width: 768px) {
    h4 {
      font-size: 16px; } }

h5 {
  font-weight: normal;
  font-family: "robotobold";
  font-size: 18px;
  color: #353535; }
  @media screen and (max-width: 1024px) {
    h5 {
      font-size: 16px; } }

h6 {
  font-weight: normal;
  font-family: "robotobold";
  font-size: 16px;
  text-transform: uppercase;
  color: #353535; }
  @media screen and (max-width: 1024px) {
    h6 {
      font-size: 14px; } }

p {
  margin: 0 0 15px; }

a {
  color: #353535;
  transition: 300ms;
  text-decoration: none; }
  a:focus {
    color: #353535;
    text-decoration: none; }
  a:hover {
    text-decoration: none;
    color: #f00000; }

textarea,
button,
select,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
  border-radius: 0px;
  -webkit-appearance: none; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none; }

.main, .page {
  position: relative;
  flex: 1 0 auto;
  font-size: 18px; }
  .main textarea,
  .main input[type="text"],
  .main input[type="email"],
  .main input[type="search"],
  .main input[type="password"], .page textarea,
  .page input[type="text"],
  .page input[type="email"],
  .page input[type="search"],
  .page input[type="password"] {
    min-height: 50px;
    width: 100%;
    background-color: #eeeeee;
    font-family: "robotoregular";
    font-size: 16px;
    padding: 14px 18px;
    margin-bottom: 15px;
    box-shadow: none;
    border: none; }
    .main textarea::placeholder,
    .main input[type="text"]::placeholder,
    .main input[type="email"]::placeholder,
    .main input[type="search"]::placeholder,
    .main input[type="password"]::placeholder, .page textarea::placeholder,
    .page input[type="text"]::placeholder,
    .page input[type="email"]::placeholder,
    .page input[type="search"]::placeholder,
    .page input[type="password"]::placeholder {
      color: #888888; }
  .main h2, .page h2 {
    text-align: center; }
  @media screen and (max-width: 1200px) {
    .main, .page {
      font-size: 16px; }
      .main textarea,
      .main input[type="text"],
      .main input[type="email"],
      .main input[type="search"],
      .main input[type="password"], .page textarea,
      .page input[type="text"],
      .page input[type="email"],
      .page input[type="search"],
      .page input[type="password"] {
        min-height: 40px;
        font-size: 14px;
        padding: 10px 15px; } }
  @media screen and (max-width: 1024px) {
    .main, .page {
      font-size: 14px; }
      .main textarea,
      .main input[type="text"],
      .main input[type="email"],
      .main input[type="search"],
      .main input[type="password"], .page textarea,
      .page input[type="text"],
      .page input[type="email"],
      .page input[type="search"],
      .page input[type="password"] {
        min-height: 35px;
        padding: 8px 15px; } }

.main .container {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media screen and (max-width: 1200px) {
    .main .container {
      padding-top: 75px;
      padding-bottom: 75px; } }
  @media screen and (max-width: 1024px) {
    .main .container {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media screen and (max-width: 768px) {
    .main .container {
      padding-top: 25px;
      padding-bottom: 25px; } }

.container {
  width: 1170px;
  padding: 0 15px;
  margin: 0 auto; }
  @media screen and (max-width: 1200px) {
    .container {
      width: auto; } }

.close {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 8px;
  top: 10px;
  opacity: 1;
  outline: none; }
  .close:before, .close:after {
    content: '';
    width: 25px;
    height: 2px;
    background-color: #f00000;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: -1px auto 0;
    transition: 300ms; }
  .close:before {
    transform: rotate(45deg); }
  .close:after {
    transform: rotate(-45deg); }
  .close:hover:before {
    transform: rotate(135deg); }
  .close:hover:after {
    transform: rotate(45deg); }
  @media screen and (max-width: 1024px) {
    .close {
      width: 20px;
      height: 20px; }
      .close:before, .close:after {
        width: 100%; } }

.btn {
  display: table;
  height: 50px;
  width: auto !important;
  background-color: #f00000 !important;
  font-family: "robotobold" !important;
  font-size: 18px;
  color: #fefefe !important;
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  border: none;
  padding: 15px 40px 14px !important;
  line-height: 1;
  transition: 300ms; }
  .btn:focus, .btn:active {
    background-color: #f00000 !important;
    color: #fff; }
  .btn:hover, .btn.active {
    background-color: #c70000 !important;
    color: #fff !important; }
  @media screen and (max-width: 1200px) {
    .btn {
      height: 40px;
      font-size: 16px;
      padding: 11px 30px 10px !important; } }
  @media screen and (max-width: 1024px) {
    .btn {
      height: 35px;
      font-size: 14px;
      padding-left: 20px !important;
      padding-right: 20px !important; } }

input.btn {
  padding-top: 14px !important; }
  @media screen and (max-width: 1200px) {
    input.btn {
      padding-top: 9px !important; } }

.check--list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .check--list li {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 5px; }
    .check--list li:before {
      content: '\f00c';
      position: absolute;
      font-family: 'fontAwesome';
      font-size: 18px;
      color: #000;
      left: 0;
      top: 4px; }

.col--location,
.col--phone,
.col--email {
  position: relative;
  padding-left: 20px; }
  .col--location:after,
  .col--phone:after,
  .col--email:after {
    content: '';
    font-family: 'fontAwesome';
    font-size: 16px;
    color: #f00000;
    position: absolute;
    top: 0px;
    left: 0; }
  @media screen and (max-width: 1200px) {
    .col--location:after,
    .col--phone:after,
    .col--email:after {
      font-size: 14px; } }

.col--location:after {
  content: '\f041'; }

.col--phone:after {
  content: '\f095'; }

.col--phone a {
  pointer-events: none; }

@media screen and (max-width: 1024px) {
  .col--phone a {
    pointer-events: auto; } }

.col--email:after {
  content: '\f0e0';
  font-size: 13px;
  top: 2px; }

.col--email a {
  text-decoration: underline; }
  .col--email a:hover {
    text-decoration: none; }

@media screen and (max-width: 1200px) {
  .col--email:after {
    font-size: 12px; } }

.gray {
  color: #afafaf; }

.bg--gray {
  background-color: #f0f0f0; }

/* header */
header {
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  font-size: 16px;
  line-height: 1;
  z-index: 9;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.7);
  /* header email */
  /* header email */
  /* header phones */
  /* header phones */
  /* header logo */
  /* header logo */
  /* header search */
  /* header search */
  /* header nav */
  /* header nav */
  /* header socials */
  /* header socials */
  /* header langs */
  /* header langs */ }
  header .up {
    background-color: #6c6c6c;
    color: #fff; }
    header .up a {
      color: #fff; }
    header .up .container {
      display: flex;
      height: 36px;
      justify-content: flex-end;
      align-items: center;
      position: relative; }
    header .up .close {
      display: none; }
  header > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 15px; }
  header .down {
    overflow: hidden;
    background-color: #f00000;
    position: relative; }
    header .down:before {
      content: '';
      background-color: #c70000;
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      left: 50%;
      top: 0;
      transform: skew(-30deg) translateX(320px); }
    header .down .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative; }
  header .col--location:after,
  header .col--phone:after,
  header .col--email:after {
    color: #fff; }
  header .col--location {
    flex: 1 0 auto; }
  header ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  header p {
    margin: 0; }
  header .btn {
    height: 32px;
    font-family: "robotoregular" !important;
    font-size: 16px;
    text-transform: inherit;
    padding-top: 7px !important;
    padding-bottom: 6px !important; }
  header .col--email {
    display: flex; }
    header .col--email li {
      margin-right: 10px; }
      header .col--email li:last-of-type {
        margin-right: 0; }
  header .phones {
    display: flex;
    margin-left: 20px; }
    header .phones li {
      margin-right: 10px; }
      header .phones li:last-of-type {
        margin-right: 0; }
  header .logo {
    display: block;
    height: 75px; }
    header .logo img {
      display: block;
      height: 100%; }
  header .search {
    display: flex;
    width: 530px;
    align-items: center;
    border: 1px solid #c0c0c0; }
    header .search input {
      width: 100%;
      font-family: "robotoregular";
      font-size: 14px;
      border: none;
      background-color: transparent;
      color: #2f2f2f;
      padding: 6px 16px;
      outline: none; }
      header .search input::placeholder {
        color: #919191; }
    header .search button {
      background-color: transparent;
      font-family: 'fontAwesome';
      font-size: 18px;
      color: #c6c6c6;
      border: none;
      padding: 0;
      margin: 0 8px;
      outline: none;
      cursor: pointer; }
      header .search button:after {
        content: '\f002'; }
  header .nav > ul {
    display: flex; }
    header .nav > ul > li > a {
      display: block;
      font-size: 18px;
      color: #fff;
      padding: 14px 13px; }
      header .nav > ul > li > a:hover {
        background-color: #c70000; }
    header .nav > ul > li.active > a {
      background-color: #c70000; }
  header .socials {
    margin-right: 65px; }
    header .socials ul {
      display: flex; }
      header .socials ul li {
        position: relative;
        margin: 0 17px; }
        header .socials ul li:after {
          content: '';
          width: 1px;
          height: 22px;
          background-color: #ff8484;
          position: absolute;
          right: -19px;
          top: 50%;
          transform: translateY(-50%); }
        header .socials ul li:last-of-type:after {
          display: none; }
        header .socials ul li a {
          font-size: 22px;
          color: #fff; }
          header .socials ul li a:hover {
            opacity: 0.7; }
  header .langs {
    font-size: 18px;
    color: #fff;
    position: absolute;
    right: 50%;
    bottom: 8px;
    padding-right: 20px;
    margin-right: -570px;
    transform: translateY(-50%);
    z-index: 1; }
    header .langs .h--arr {
      display: block;
      position: absolute;
      right: 7px;
      top: 55%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid #ffffff; }
    header .langs .langs__title {
      display: block; }
    header .langs ul {
      display: none;
      background-color: rgba(240, 0, 0, 0.9);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 8px 12px;
      box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8); }
      header .langs ul li {
        margin: 5px 0; }
        header .langs ul li a {
          color: #fff; }
          header .langs ul li a:hover {
            text-decoration: underline; }
  @media screen and (min-width: 1025px) {
    header.fix {
      position: fixed; }
      header.fix .up {
        display: none; } }
  @media screen and (max-width: 1200px) {
    header {
      font-size: 14px; }
      header .langs {
        right: 15px;
        margin: 0; }
      header .col--email:after {
        top: 1px; } }
  @media screen and (max-width: 1024px) {
    header {
      position: fixed; }
      header .socials {
        margin: 15px 0 10px; }
        header .socials ul {
          justify-content: center; }
          header .socials ul li:after {
            height: 20px;
            background-color: #fff; }
          header .socials ul li a {
            font-size: 20px; }
      header .langs {
        font-size: 16px;
        color: #f00000;
        bottom: auto;
        top: 38px;
        right: auto;
        left: 60px; }
        header .langs .h--arr {
          border-top: 5px solid #353535; }
        header .langs .langs__title {
          color: #353535; }
        header .langs ul {
          padding: 5px 10px; }
      header .nav--btn {
        width: 35px;
        height: 30px;
        position: absolute;
        left: 12px;
        top: 23px;
        padding: 0;
        margin: 0;
        outline: none;
        float: none; }
        header .nav--btn span {
          position: absolute;
          left: 0;
          right: 0;
          width: 85%;
          height: 3px;
          margin: 0 auto;
          background-color: #f00000;
          transition: 350ms; }
          header .nav--btn span:nth-of-type(1) {
            top: 7px; }
          header .nav--btn span:nth-of-type(2) {
            top: 14px; }
          header .nav--btn span:nth-of-type(3) {
            top: 21px; }
        header .nav--btn.open span:nth-of-type(1) {
          top: 14px;
          transform: rotate(45deg); }
        header .nav--btn.open span:nth-of-type(2) {
          opacity: 0; }
        header .nav--btn.open span:nth-of-type(3) {
          top: 14px;
          transform: rotate(-45deg); }
      header .up {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        padding: 10px 20px; }
        header .up .container {
          display: block;
          height: auto;
          padding: 0; }
        header .up .close {
          display: block;
          right: 5px;
          top: 5px; }
      header .col--location,
      header .col--email,
      header .col--phone {
        display: table;
        margin: 15px auto; }
        header .col--location li,
        header .col--email li,
        header .col--phone li {
          margin: 0 0 5px; }
      header .col--location {
        margin: 10px auto; }
      header .search {
        width: 100%;
        background-color: #fff;
        position: fixed;
        left: 0;
        right: 0;
        top: 77px;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        transition: 300ms; }
        header .search.open {
          opacity: 1;
          visibility: visible; }
      header .nav {
        display: none;
        position: fixed;
        background-color: #f00000;
        width: 100%;
        max-height: calc(100% - 77px);
        top: 77px;
        left: 0;
        right: 0;
        overflow: auto;
        padding: 15px 15px; }
        header .nav > ul {
          display: block;
          width: 100%; }
          header .nav > ul > li > a {
            font-size: 16px;
            padding: 10px 15px; }
      header > .container {
        justify-content: center; }
      header .btn {
        display: block;
        width: 35px !important;
        height: 35px;
        position: fixed;
        bottom: 50px;
        right: 15px;
        border-radius: 100%;
        color: transparent !important;
        overflow: hidden;
        padding: 0 !important;
        box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.7); }
        header .btn:hover {
          color: transparent !important; }
        header .btn:after {
          content: '\f095';
          font-family: 'fontAwesome';
          font-size: 18px !important;
          color: #fff;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%); }
      header .phones--btn {
        display: block;
        font-family: 'fontAwesome';
        font-size: 20px;
        color: #f00000;
        position: absolute;
        top: 28px;
        right: 15px; }
        header .phones--btn:after {
          content: '\f095'; }
        header .phones--btn:hover {
          text-decoration: none; }
      header .search--btn {
        position: absolute;
        top: 50%;
        right: 50px;
        font-family: 'fontAwesome';
        font-size: 18px;
        color: #f00000;
        text-decoration: none;
        transform: translateY(-50%); }
        header .search--btn:after {
          content: '\f002'; } }
  @media screen and (max-width: 768px) {
    header .nav--btn {
      left: 5px; }
    header .phones--btn {
      font-size: 18px;
      right: 10px;
      top: 30px; }
    header .search--btn {
      right: 35px; }
    header .langs {
      left: 45px;
      font-size: 14px; }
      header .langs .langs__title {
        font-size: 14px; }
    header .btn {
      bottom: 25px; } }
  @media screen and (max-width: 360px) {
    header .col--location {
      text-align: center; } }

/* header */
/* main content */
.modal-open {
  overflow-y: visible; }
  .modal-open .modal {
    overflow: auto; }

.fancybox-enabled {
  overflow: visible; }
  .fancybox-enabled body {
    overflow-x: hidden; }

.preload {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9999; }
  .preload.load {
    opacity: 0;
    transition: 500ms; }

/* main slider */
.slider {
  margin-bottom: 0px !important; }
  .slider .slick-slide {
    overflow: hidden;
    position: relative;
    outline: none; }
    .slider .slick-slide img {
      display: block;
      width: 100%;
      height: auto; }
    .slider .slick-slide .slider__block {
      width: 660px;
      position: absolute;
      left: 50%;
      right: 0;
      top: 50%;
      text-align: center;
      color: #fff;
      margin: 0 auto 0 -583px;
      transform: translateY(-50%); }
      .slider .slick-slide .slider__block .slider__block--title {
        display: block;
        background-color: rgba(0, 0, 0, 0.3);
        font-family: "robotobold";
        font-size: 48px;
        padding: 25px 30px; }
      .slider .slick-slide .slider__block .btn {
        font-family: "robotobold";
        text-transform: uppercase;
        margin: 40px auto 0; }
  .slider .slick-dots {
    bottom: 30px; }
    .slider .slick-dots li {
      margin: 0px 2px; }
      .slider .slick-dots li button {
        width: 15px;
        height: 15px;
        background-color: #efefef;
        border-radius: 100%;
        border: 1px solid #3361eb;
        transition: 300ms; }
        .slider .slick-dots li button:before {
          display: none; }
        .slider .slick-dots li button:hover {
          background-color: #3361eb; }
      .slider .slick-dots li.slick-active button {
        background-color: #3361eb; }
  .slider.arrow .slick-arrow.slick-prev {
    left: 45px; }
  .slider.arrow .slick-arrow.slick-next {
    right: 45px; }
  @media screen and (max-width: 1440px) {
    .slider .slick-slide .slider__block {
      left: 15%;
      margin: 0; }
      .slider .slick-slide .slider__block .slider__block--title {
        font-size: 40px; }
    .slider.arrow .slick-arrow.slick-prev {
      left: 30px; }
    .slider.arrow .slick-arrow.slick-next {
      right: 30px; } }
  @media screen and (max-width: 1200px) {
    .slider .slick-slide {
      height: 380px; }
      .slider .slick-slide img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center; }
      .slider .slick-slide .slider__block {
        width: 600px;
        left: 0;
        right: 0;
        margin: 0 auto; }
        .slider .slick-slide .slider__block .slider__block--title {
          font-size: 30px;
          padding: 20px; } }
  @media screen and (max-width: 1024px) {
    .slider .slick-slide {
      height: 300px; }
      .slider .slick-slide .slider__block {
        width: auto;
        left: 75px;
        right: 75px; }
        .slider .slick-slide .slider__block .slider__block--title {
          display: table;
          font-size: 24px;
          padding: 15px 25px;
          margin: 0 auto; }
        .slider .slick-slide .slider__block .btn {
          margin-top: 20px; } }
  @media screen and (max-width: 768px) {
    .slider .slick-slide {
      height: 275px; }
      .slider .slick-slide .slider__block .slider__block--title {
        font-size: 20px; }
    .slider.arrow .slick-arrow.slick-prev {
      left: 15px; }
    .slider.arrow .slick-arrow.slick-next {
      right: 15px; } }
  @media screen and (max-width: 480px) {
    .slider .slick-slide {
      height: 220px; }
      .slider .slick-slide .slider__block {
        line-height: 1.1;
        left: 50px;
        right: 50px; }
        .slider .slick-slide .slider__block .slider__block--title {
          font-size: 18px;
          padding: 15px 20px; }
        .slider .slick-slide .slider__block .btn {
          margin: 15px auto 0; }
    .slider.arrow .slick-arrow.slick-prev {
      left: 7px; }
    .slider.arrow .slick-arrow.slick-next {
      right: 7px; } }

.arrow .slick-arrow {
  width: 80px;
  height: 80px;
  border: 3px solid #f9f9fb;
  border-radius: 100%;
  z-index: 1;
  transition: 300ms; }
  .arrow .slick-arrow:before {
    display: none; }
  .arrow .slick-arrow:after {
    content: '';
    background: url(../img/arrowPrev.png) no-repeat center center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .arrow .slick-arrow.slick-prev {
    left: 15px; }
  .arrow .slick-arrow.slick-next {
    right: 15px; }
    .arrow .slick-arrow.slick-next:after {
      transform: rotateY(180deg); }
  .arrow .slick-arrow:hover {
    opacity: 0.7; }

@media screen and (max-width: 1440px) {
  .arrow .slick-arrow {
    width: 60px;
    height: 60px; }
    .arrow .slick-arrow:after {
      background-size: 18px; } }

@media screen and (max-width: 1200px) {
  .arrow .slick-arrow {
    width: 50px;
    height: 50px; }
    .arrow .slick-arrow:after {
      background-size: 15px; } }

@media screen and (max-width: 1024px) {
  .arrow .slick-arrow {
    width: 40px;
    height: 40px;
    border: 2px solid #fff; }
    .arrow .slick-arrow:after {
      background-size: 11px; } }

@media screen and (max-width: 768px) {
  .arrow .slick-arrow {
    width: 35px;
    height: 35px; }
    .arrow .slick-arrow:after {
      background-size: 8px; } }

/* main slider */
/* main about */
.about {
  display: flex;
  flex-wrap: wrap; }
  .about .left {
    width: 47%;
    position: relative; }
    .about .left:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(87, 87, 87, 0.7) url(../img/bg.png) no-repeat center center;
      background-size: cover; }
    .about .left > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      filter: grayscale(100%); }
  .about .right {
    width: 53%;
    padding: 100px 0 100px 125px; }
  .about .about__img {
    width: 425px;
    position: absolute;
    top: 50%;
    right: -50px;
    transform: translateY(-50%);
    z-index: 1;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4); }
  .about .about__block {
    max-width: 500px; }
  .about .about__works {
    display: flex;
    justify-content: space-between;
    line-height: 1;
    margin-top: 35px; }
    .about .about__works .about__works--col {
      text-align: center; }
      .about .about__works .about__works--col span {
        display: block; }
      .about .about__works .about__works--col .gray {
        font-family: "robotobold"; }
      .about .about__works .about__works--col .num {
        font-family: "robotobold";
        font-size: 36px;
        color: #f00000; }
  .about h1 {
    color: #6c6c6c; }
  @media screen and (max-width: 1200px) {
    .about .right {
      padding: 50px 15px 50px 75px; }
    .about .about__works .about__works--col .num {
      font-size: 32px; }
    .about .about__img {
      right: -35px; } }
  @media screen and (max-width: 768px) {
    .about {
      flex-wrap: wrap; }
      .about .left {
        width: 100%;
        order: 2; }
        .about .left > img {
          position: absolute;
          top: 0;
          left: 0; }
      .about .right {
        width: 100%;
        order: 1;
        padding: 30px 15px; }
      .about .about__block {
        max-width: 100%; }
      .about .about__works {
        justify-content: center;
        margin-top: 15px; }
        .about .about__works .about__works--col {
          margin: 15px 15px 0; }
      .about .about__img {
        display: flex;
        position: relative;
        justify-content: center;
        left: 0;
        top: 0;
        transform: translate(0, 0);
        margin: 35px auto; }
        .about .about__img img {
          max-width: 100%; } }
  @media screen and (max-width: 480px) {
    .about .about__img {
      width: calc(100% - 30px);
      margin: 35px 15px; }
      .about .about__img img {
        width: 100%;
        height: auto; }
    .about .about__works .about__works--col {
      margin: 0 10px; }
      .about .about__works .about__works--col .num {
        font-size: 24px;
        margin: 5px 0; } }
  @media screen and (max-width: 380px) {
    .about .about__works .about__works--col:first-of-type {
      margin-left: 0; }
    .about .about__works .about__works--col:last-of-type {
      margin-right: 0; }
    .about .about__works .about__works--col .num {
      font-size: 20px; }
    .about .about__works .about__works--col p {
      margin-bottom: 0; } }

/* main about */
/* main catalog */
.cat {
  padding-top: 100px;
  overflow: hidden; }
  @media screen and (max-width: 1440px) {
    .cat {
      padding-top: 75px; } }
  @media screen and (max-width: 1024px) {
    .cat {
      padding-top: 50px; } }
  @media screen and (max-width: 768px) {
    .cat {
      padding-top: 30px; } }

.cat__block {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 8px;
  margin: 0 -10px; }
  .cat__block .cat__item {
    height: 445px;
    width: calc(33.33% - 20px);
    overflow: hidden;
    margin: 0 10px 20px;
    position: relative; }
    .cat__block .cat__item:nth-last-of-type(-n+3) {
      margin-bottom: 0; }
    .cat__block .cat__item:before {
      content: '';
      position: absolute;
      top: 22px;
      left: 22px;
      right: 22px;
      bottom: 22px;
      border: 2px solid #f0f0f0;
      transition: 300ms;
      pointer-events: none;
      opacity: 0;
      z-index: 1; }
    .cat__block .cat__item:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
      pointer-events: none;
      transition: 300ms; }
    .cat__block .cat__item img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: 300ms; }
    .cat__block .cat__item .cat__title {
      font-family: "robotobold";
      font-size: 30px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 30px;
      right: 30px;
      transform: translateY(-50%);
      line-height: 1.2;
      z-index: 1; }
      .cat__block .cat__item .cat__title span {
        display: block;
        text-transform: uppercase; }
    .cat__block .cat__item:hover:before {
      opacity: 1; }
    .cat__block .cat__item:hover:after {
      background-color: rgba(0, 0, 0, 0.5); }
    .cat__block .cat__item:hover img {
      transform: scale(1.1); }
  @media screen and (max-width: 1440px) {
    .cat__block .cat__item {
      height: 400px; }
      .cat__block .cat__item .cat__title {
        font-size: 26px; } }
  @media screen and (max-width: 1366px) {
    .cat__block .cat__item {
      height: 375px; } }
  @media screen and (max-width: 1200px) {
    .cat__block .cat__item {
      height: 330px; }
      .cat__block .cat__item:before {
        top: 18px;
        left: 18px;
        right: 18px;
        bottom: 18px;
        opacity: 1; }
      .cat__block .cat__item:after {
        background-color: rgba(0, 0, 0, 0.5); }
      .cat__block .cat__item .cat__title {
        font-size: 22px; } }
  @media screen and (max-width: 1024px) {
    .cat__block .cat__item {
      height: 280px; }
      .cat__block .cat__item .cat__title {
        font-size: 18px; } }
  @media screen and (max-width: 768px) {
    .cat__block .cat__item {
      width: calc(50% - 20px); }
      .cat__block .cat__item:before {
        left: 15px;
        right: 15px;
        bottom: 15px;
        top: 15px; } }
  @media screen and (max-width: 480px) {
    .cat__block {
      padding: 0 15px;
      margin: 0; }
      .cat__block .cat__item {
        width: 100%;
        margin: 0 0 20px !important; } }

/* main catalog */
/* main partners */
.partners {
  padding: 90px 0 0; }
  @media screen and (max-width: 1440px) {
    .partners {
      padding-top: 75px; } }
  @media screen and (max-width: 1200px) {
    .partners {
      padding-top: 50px; } }
  @media screen and (max-width: 1024px) {
    .partners {
      padding-top: 30px; } }

.partners__slider .slick-list {
  padding: 0 7% !important; }

.partners__slider .slick-slide {
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 0 30px; }
  .partners__slider .slick-slide img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center center;
    transition-duration: 300ms !important;
    opacity: 0.3; }

.partners__slider .slick-track {
  margin: 0 auto; }
  .partners__slider .slick-track .slick-slide.slick-current img {
    opacity: 1; }
  .partners__slider .slick-track .slick-slide.slick-current + .slick-active img {
    opacity: 1; }
  .partners__slider .slick-track .slick-slide.slick-current + .slick-active + .slick-active img {
    opacity: 1; }
  .partners__slider .slick-track .slick-slide.slick-current + .slick-active + .slick-active + .slick-active img {
    opacity: 0.3; }
  .partners__slider .slick-track .slick-slide.slick-active + .slick-active img {
    opacity: 1; }

@media screen and (max-width: 1440px) {
  .partners__slider .slick-slide {
    margin: 0 30px; }
  .partners__slider .slick-track .slick-slide.slick-current + .slick-active + .slick-active img {
    opacity: 0.3; } }

@media screen and (max-width: 1200px) {
  .partners__slider .slick-list {
    padding: 0 !important; }
  .partners__slider .slick-slide img {
    opacity: 1 !important; } }

@media screen and (max-width: 768px) {
  .partners__slider .slick-slide {
    height: 120px;
    margin: 0 20px; } }

/* main partners */
/* main news */
.news .container {
  padding-top: 75px; }

@media screen and (max-width: 1024px) {
  .news .container {
    padding-top: 50px; } }

@media screen and (max-width: 768px) {
  .news .container {
    padding-top: 25px; } }

.news__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .news__block .news__item {
    display: flex;
    flex-direction: column;
    width: calc(50% - 15px);
    background-color: #c70000;
    color: #fff; }
    .news__block .news__item .news__img {
      height: 255px;
      overflow: hidden; }
      .news__block .news__item .news__img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        transition-duration: 300ms; }
    .news__block .news__item .news__info {
      flex: 1 0 auto;
      position: relative;
      padding: 18px 27px 20px;
      overflow: hidden;
      z-index: 1; }
      .news__block .news__item .news__info:before {
        content: '';
        width: 50%;
        background-color: #fe0000;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 74%;
        z-index: -1;
        transform: skew(-37deg);
        transition: 300ms; }
      .news__block .news__item .news__info .date {
        display: block;
        margin-bottom: 15px; }
      .news__block .news__item .news__info p {
        margin: 0; }
    .news__block .news__item:hover .news__img img {
      transform: scale(1.1); }
    .news__block .news__item:hover .news__info:before {
      left: 85%; }
  @media screen and (max-width: 1024px) {
    .news__block .news__item .news__img {
      height: 200px; } }
  @media screen and (max-width: 768px) {
    .news__block .news__item .news__img {
      height: 150px; }
    .news__block .news__item .news__info {
      padding: 15px 20px 15px; } }
  @media screen and (max-width: 600px) {
    .news__block .news__item {
      width: calc(50% - 10px); } }
  @media screen and (max-width: 500px) {
    .news__block .news__item {
      width: 100%;
      margin-bottom: 20px; }
      .news__block .news__item:last-of-type {
        margin-bottom: 0; }
      .news__block .news__item .news__img {
        height: auto; }
        .news__block .news__item .news__img img {
          height: auto; }
      .news__block .news__item .news__info .date {
        margin-bottom: 10px; } }

/* main news */
/* main form */
.form {
  position: relative; }
  .form:before {
    content: '';
    background: url(../img/bg-1.png) no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .form > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
  .form .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding: 75px 15px; }
  .form h2 {
    width: 100%;
    color: #fff; }
  .form input[type="text"],
  .form input[type="email"] {
    width: calc(50% - 140px);
    margin-bottom: 0; }
  .form .btn {
    width: 250px !important; }
  @media screen and (max-width: 1024px) {
    .form .container {
      padding: 50px 15px; } }
  @media screen and (max-width: 768px) {
    .form .container {
      padding: 35px 15px; } }
  @media screen and (max-width: 680px) {
    .form input[type="text"],
    .form input[type="email"] {
      width: 100%;
      margin-bottom: 15px; }
    .form .btn {
      margin: 0 auto; } }

/* main form */
/* main content */
/* page content */
.page {
  padding-bottom: 80px; }
  .page .news1 .date, .page .news .date {
    color: #fe0000; }
  .page .page_nav .active {
    color: #fe0000; }
  .page #ask-form {
    max-width: 350px; }
  .page .write {
    color: #fe0000;
    text-decoration: none; }
    .page .write:hover {
      text-decoration: underline; }
  .page .back_link {
    margin: 25px 0; }
  .page p a {
    color: #fe0000;
    text-decoration: underline; }
    .page p a:hover {
      text-decoration: none; }
  .page h2 {
    text-align: left;
    text-transform: inherit; }
    .page h2 a {
      font-size: 16px;
      color: #fe0000; }
  .page .page--block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .page .page--block .left {
      width: calc(100% - 400px); }
    .page .page--block .right {
      max-width: 350px; }
      .page .page--block .right img {
        max-width: 100% !important;
        width: auto !important;
        height: auto !important;
        float: none !important;
        margin: 0 !important; }
  @media screen and (max-width: 1024px) {
    .page {
      padding-bottom: 50px; }
      .page #photos-list td img {
        width: 100%;
        height: auto; }
      .page .page--block .left {
        width: calc(100% - 300px); }
      .page .page--block .right {
        width: 250px; } }
  @media screen and (max-width: 768px) {
    .page {
      padding-bottom: 30px; }
      .page .page--block .left {
        width: calc(100% - 250px); }
      .page .page--block .right {
        width: 200px; } }
  @media screen and (max-width: 480px) {
    .page p img, .page p strong img {
      width: 100% !important;
      height: auto !important;
      display: block !important;
      float: none !important;
      margin: 0 0 10px 0 !important; }
    .page .page--block .left {
      width: 100%; }
    .page .page--block .right {
      margin: 15px 0;
      width: 100%; }
    .page #photos-list td {
      width: 50%;
      display: inline-block;
      vertical-align: top; } }

/* breadcrumbs */
.breadcrumbs {
  font-size: 16px;
  margin: 30px 0 50px; }
  .breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .breadcrumbs ul li {
      padding: 5px 0px;
      color: #fe0000;
      display: inline-block;
      vertical-align: top; }
      .breadcrumbs ul li a {
        color: #353535;
        text-decoration: none; }
        .breadcrumbs ul li a:hover {
          color: #f00000; }
      .breadcrumbs ul li:after {
        content: '/';
        margin: 3px 2px 0px 7px;
        color: #353535;
        display: inline-block;
        vertical-align: top; }
      .breadcrumbs ul li:last-of-type:after {
        display: none; }
  @media screen and (max-width: 1200px) {
    .breadcrumbs {
      font-size: 14px;
      margin: 20px 0 40px; } }
  @media screen and (max-width: 1024px) {
    .breadcrumbs {
      padding-top: 10px;
      margin: 0px 0 25px;
      line-height: 1; }
      .breadcrumbs ul li:after {
        margin-top: 0; } }
  @media screen and (max-width: 768px) {
    .breadcrumbs {
      font-size: 12px;
      margin-bottom: 20px; }
      .breadcrumbs ul li:after {
        margin: 0px 2px 0 5px; } }

/* breadcrumbs */
/* page about */
.page .about .right {
  padding-top: 0;
  padding-bottom: 0; }

@media screen and (max-width: 380px) {
  .page .about .right {
    margin-bottom: 20px; } }

/* page about */
/* page сatalog */
.page .cat {
  padding-top: 0; }

.cat--inside {
  display: flex;
  flex-wrap: wrap;
  position: relative; }
  .cat--inside .filter {
    width: 100%; }
  .cat--inside .left {
    width: 325px; }
  .cat--inside .right {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 325px); }
  .cat--inside .filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: absolute;
    top: -75px;
    right: 0;
    z-index: 1; }
    .cat--inside .filter .filter__col {
      margin-left: 14px; }
  .cat--inside .cat--inside__item {
    display: flex;
    flex-direction: column;
    width: calc(33.33% - 26px);
    background-color: #ffffff;
    color: #353535;
    margin: 0 0 26px 26px; }
    .cat--inside .cat--inside__item .cat--inside__img {
      height: 240px;
      overflow: hidden; }
      .cat--inside .cat--inside__item .cat--inside__img img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; }
    .cat--inside .cat--inside__item .cat--inside__info {
      flex: 1 0 auto;
      position: relative;
      padding: 25px 15px 45px; }
      .cat--inside .cat--inside__item .cat--inside__info h5 {
        margin: 0 0 10px;
        transition: 300ms; }
      .cat--inside .cat--inside__item .cat--inside__info .btn {
        display: block;
        height: 38px;
        width: auto !important;
        text-transform: inherit;
        padding: 10px 20px 10px !important;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0; }
      .cat--inside .cat--inside__item .cat--inside__info .stiker {
        position: absolute;
        top: -40px;
        right: 15px; }
    .cat--inside .cat--inside__item:hover .cat--inside__info h5 {
      color: #f00000; }
  @media screen and (max-width: 1200px) {
    .cat--inside .filter {
      top: -66px; }
    .cat--inside .cat--inside__item .cat--inside__img {
      height: 210px; } }
  @media screen and (max-width: 1024px) {
    .cat--inside .filter {
      top: -51px; }
    .cat--inside .cat--inside__item {
      width: calc(50% - 26px); }
      .cat--inside .cat--inside__item .cat--inside__info {
        padding-bottom: 40px; } }
  @media screen and (max-width: 768px) {
    .cat--inside {
      margin: 0 -10px; }
      .cat--inside .left {
        width: 100%;
        order: 2; }
      .cat--inside .right {
        width: 100%;
        order: 1; }
      .cat--inside .cat--inside__item {
        width: calc(33.33% - 20px);
        margin: 0 10px 20px; }
        .cat--inside .cat--inside__item .cat--inside__info {
          padding-top: 15px; }
          .cat--inside .cat--inside__item .cat--inside__info .stiker {
            top: -65px; }
      .cat--inside .filter {
        justify-content: flex-start;
        top: 0;
        position: relative; }
        .cat--inside .filter .filter__col {
          margin: 0 0 15px 10px; } }
  @media screen and (max-width: 600px) {
    .cat--inside .cat--inside__item {
      width: calc(50% - 20px); }
      .cat--inside .cat--inside__item .cat--inside__img {
        height: 200px; } }
  @media screen and (max-width: 420px) {
    .cat--inside {
      margin: 0; }
      .cat--inside .cat--inside__item {
        width: 100%;
        margin: 0 0 20px; }
        .cat--inside .cat--inside__item .cat--inside__img {
          height: auto; }
          .cat--inside .cat--inside__item .cat--inside__img img {
            height: auto; }
      .cat--inside .filter .filter__col {
        margin-left: 0;
        margin-right: 10px; } }

.stiker {
  display: flex;
  width: 65px;
  height: 65px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ed1c24;
  border-radius: 100%;
  font-size: 9px;
  color: #fff;
  text-align: center;
  line-height: 1; }
  .stiker .num {
    font-family: "robotobold";
    font-size: 24px; }
  .stiker .year {
    font-size: 10px;
    text-transform: uppercase; }
  @media screen and (max-width: 768px) {
    .stiker {
      width: 55px;
      height: 55px;
      font-size: 8px; }
      .stiker .num {
        font-size: 18px; } }

.sidebar {
  line-height: 1.1; }
  .sidebar * {
    user-select: none; }
  .sidebar ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .sidebar ul li {
      position: relative; }
  .sidebar .arr {
    display: flex;
    width: 40px;
    height: 55px;
    font-family: 'fontAwesome';
    font-size: 16px;
    color: #363636;
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    transition: 300ms; }
    .sidebar .arr:after {
      content: '\f107'; }
  .sidebar .open > .arr {
    transform: rotate(-180deg); }
  .sidebar > ul {
    width: 100%;
    overflow: hidden; }
    .sidebar > ul > li {
      overflow: hidden;
      margin-bottom: 2px; }
      .sidebar > ul > li > a {
        display: flex;
        flex-direction: column;
        min-height: 55px;
        justify-content: center;
        background-color: #fff;
        font-size: 18px;
        color: #252525;
        padding: 10px 35px 10px 58px;
        border-left: 6px solid #3e3e3e;
        position: relative;
        z-index: 2; }
        .sidebar > ul > li > a img {
          max-width: 14px;
          max-height: 14px;
          object-fit: contain;
          object-position: center center;
          position: absolute;
          top: 50%;
          left: 19px;
          transform: translateY(-50%);
          transition: 300ms;
          filter: grayscale(100%); }
        .sidebar > ul > li > a span {
          display: block;
          font-size: 12px;
          color: #a9a9a9; }
        .sidebar > ul > li > a:hover img {
          filter: grayscale(0); }
      .sidebar > ul > li.active > a {
        box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.5); }
        .sidebar > ul > li.active > a img {
          filter: grayscale(0); }
      .sidebar > ul > li > ul {
        display: none; }
        .sidebar > ul > li > ul > li {
          margin-bottom: 2px; }
          .sidebar > ul > li > ul > li > a {
            display: flex;
            align-items: center;
            min-height: 55px;
            font-size: 18px;
            background-color: #ed1c24;
            color: #fff;
            position: relative;
            padding: 10px 35px 10px 26px;
            z-index: 1; }
            .sidebar > ul > li > ul > li > a:hover {
              opacity: 0.7; }
          .sidebar > ul > li > ul > li > .arr {
            color: #fff; }
          .sidebar > ul > li > ul > li.active > a {
            box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.5); }
          .sidebar > ul > li > ul > li ul {
            display: none; }
            .sidebar > ul > li > ul > li ul li {
              margin-bottom: 1px; }
              .sidebar > ul > li > ul > li ul li:last-of-type {
                border-bottom: none; }
              .sidebar > ul > li > ul > li ul li a {
                display: flex;
                background-color: #fff;
                align-items: center;
                min-height: 50px;
                padding: 10px 20px 10px 45px;
                position: relative; }
                .sidebar > ul > li > ul > li ul li a:after {
                  content: '';
                  width: 5px;
                  height: 5px;
                  background-color: #353535;
                  border-radius: 100%;
                  position: absolute;
                  top: 50%;
                  left: 30px;
                  transform: translateY(-50%); }
              .sidebar > ul > li > ul > li ul li.active > a {
                color: #f00000; }
  @media screen and (max-width: 1024px) {
    .sidebar {
      font-size: 16px; }
      .sidebar > ul > li > a {
        min-height: 50px;
        font-size: 16px;
        padding-left: 40px; }
        .sidebar > ul > li > a img {
          left: 15px; }
      .sidebar > ul > li > ul > li > a {
        min-height: 50px;
        font-size: 16px; }
      .sidebar > ul > li > ul > li ul li a {
        padding-left: 40px; }
        .sidebar > ul > li > ul > li ul li a:after {
          left: 27px; }
      .sidebar .arr {
        height: 50px; } }
  @media screen and (max-width: 768px) {
    .sidebar {
      font-size: 14px;
      margin: 0 10px;
      margin-top: 30px; }
      .sidebar > ul > li > a {
        font-size: 14px;
        padding-left: 30px;
        border-left: 3px solid #3e3e3e; }
        .sidebar > ul > li > a img {
          max-width: 12px;
          max-height: 12px;
          left: 9px; }
      .sidebar > ul > li > ul > li > a {
        font-size: 14px; } }
  @media screen and (max-width: 420px) {
    .sidebar {
      margin: 25px 0 0; } }

.edd-root {
  width: auto;
  line-height: 1; }
  .edd-root:after {
    display: none; }
  .edd-root .edd-head {
    border: none; }
    .edd-root .edd-head .edd-value {
      background-color: #fff;
      padding: 12px 50px 12px 25px; }
  .edd-root .edd-arrow {
    right: 22px; }
  .edd-root .edd-option-selected:not(.edd-option-disabled) {
    color: #f00000; }
  .edd-root .edd-option-selected:before {
    display: none; }
  .edd-root .edd-option-focused:not(.edd-option-disabled) {
    color: #f00000; }
  .edd-root .edd-option {
    padding: 5px 20px 5px 25px; }
  .edd-root .edd-body {
    padding: 5px 0; }
  @media screen and (max-width: 1024px) {
    .edd-root .edd-head .edd-value {
      font-size: 14px;
      padding: 11px 35px 11px 15px; }
    .edd-root .edd-arrow {
      right: 12px; } }

.cat--unit {
  display: flex;
  flex-wrap: wrap; }
  .cat--unit .left {
    position: relative;
    width: calc(100% - 470px); }
    .cat--unit .left h6 {
      text-transform: inherit;
      margin: 15px 0; }
    .cat--unit .left .stiker {
      width: 170px;
      height: 170px;
      font-family: "robotobold";
      font-size: 16px;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1; }
      .cat--unit .left .stiker .num {
        font-size: 68px; }
      .cat--unit .left .stiker .year {
        font-size: 26px; }
  .cat--unit .right {
    width: 450px;
    position: relative;
    background-color: #fff;
    padding: 40px 30px 45px;
    margin-left: 20px; }
    .cat--unit .right ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .cat--unit .right ul li {
        margin: 2px 0; }
    .cat--unit .right .price {
      display: block;
      font-family: "robotobold";
      font-size: 36px;
      color: #f00000;
      line-height: 1;
      margin: 40px 0 30px; }
      .cat--unit .right .price .price__title {
        display: block;
        font-family: "robotomedium";
        font-size: 18px;
        color: #a1a1a1;
        margin-bottom: 10px; }
      .cat--unit .right .price sup {
        font-size: 20px; }
    .cat--unit .right .btn {
      width: 300px !important; }
  .cat--unit h1 {
    font-size: 26px;
    text-transform: inherit; }
  @media screen and (max-width: 1200px) {
    .cat--unit .left {
      width: calc(100% - 370px); }
      .cat--unit .left .stiker {
        width: 130px;
        height: 130px;
        font-size: 14px; }
        .cat--unit .left .stiker .num {
          font-size: 50px; }
        .cat--unit .left .stiker .year {
          font-size: 22px; }
    .cat--unit .right {
      width: 350px;
      padding: 30px 20px 35px; }
      .cat--unit .right .price {
        font-size: 30px; }
    .cat--unit h1 {
      font-size: 22px; } }
  @media screen and (max-width: 1024px) {
    .cat--unit h1 {
      font-size: 20px; }
    .cat--unit .left .stiker {
      width: 110px;
      height: 110px;
      font-size: 12px; }
      .cat--unit .left .stiker .num {
        font-size: 40px; }
      .cat--unit .left .stiker .year {
        font-size: 18px; } }
  @media screen and (max-width: 768px) {
    .cat--unit .left {
      width: 100%; }
      .cat--unit .left .stiker {
        width: 80px;
        height: 80px;
        font-size: 10px; }
        .cat--unit .left .stiker .num {
          font-size: 30px; }
        .cat--unit .left .stiker .year {
          font-size: 14px; }
    .cat--unit .right {
      width: 100%;
      padding: 20px 20px 25px;
      margin-left: 0; }
      .cat--unit .right .price {
        font-size: 24px;
        margin: 20px 0 20px; }
        .cat--unit .right .price .price__title {
          font-size: 16px;
          margin-bottom: 5px; }
      .cat--unit .right .btn {
        width: auto !important;
        padding-left: 40px !important;
        padding-right: 40px !important; } }

.cat--unit__for .slick-slide {
  height: 390px;
  outline: none; }
  .cat--unit__for .slick-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center; }

@media screen and (max-width: 1024px) {
  .cat--unit__for .slick-slide {
    height: 320px; } }

@media screen and (max-width: 480px) {
  .cat--unit__for .slick-slide {
    height: 270px; } }

@media screen and (max-width: 380px) {
  .cat--unit__for .slick-slide {
    height: 230px; } }

.cat--unit__nav {
  margin: 0 -10px; }
  .cat--unit__nav .slick-slide {
    height: 124px;
    outline: none;
    margin: 0 10px;
    position: relative;
    cursor: pointer; }
    .cat--unit__nav .slick-slide:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 3px solid #f00000;
      transition: 300ms;
      opacity: 0; }
    .cat--unit__nav .slick-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .cat--unit__nav .slick-slide span {
      display: block;
      background-color: rgba(0, 0, 0, 0.3);
      padding: 10px 5px;
      font-size: 16px;
      color: #fff;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      line-height: 1; }
    .cat--unit__nav .slick-slide:hover:after, .cat--unit__nav .slick-slide.slick-current:after {
      opacity: 1; }
  .cat--unit__nav .slick-arrow {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f00000;
    border-radius: 100%;
    line-height: 1;
    overflow: hidden;
    transition: 300ms;
    z-index: 1; }
    .cat--unit__nav .slick-arrow:before {
      display: none; }
    .cat--unit__nav .slick-arrow:after {
      content: '';
      font-family: 'fontAwesome';
      font-size: 20px;
      color: #fff;
      line-height: 12px; }
    .cat--unit__nav .slick-arrow.slick-prev {
      left: -5px; }
      .cat--unit__nav .slick-arrow.slick-prev:after {
        content: '\f104'; }
    .cat--unit__nav .slick-arrow.slick-next {
      right: -5px; }
      .cat--unit__nav .slick-arrow.slick-next:after {
        content: '\f105'; }
    .cat--unit__nav .slick-arrow:hover {
      opacity: 0.7; }
  @media screen and (max-width: 768px) {
    .cat--unit__nav {
      margin-bottom: 20px; }
      .cat--unit__nav .slick-arrow.slick-prev {
        left: 15px; }
      .cat--unit__nav .slick-arrow.slick-next {
        right: 15px; }
      .cat--unit__nav .slick-slide span {
        font-size: 14px; } }
  @media screen and (max-width: 768px) {
    .cat--unit__nav .slick-slide span {
      font-size: 12px; } }
  @media screen and (max-width: 680px) {
    .cat--unit__nav {
      margin: 0 -5px 20px; }
      .cat--unit__nav .slick-slide {
        margin: 0 5px; }
      .cat--unit__nav .slick-arrow.slick-prev {
        left: 10px; }
      .cat--unit__nav .slick-arrow.slick-next {
        right: 10px; } }
  @media screen and (max-width: 400px) {
    .cat--unit__nav .slick-slide {
      height: 100px; } }
  @media screen and (max-width: 340px) {
    .cat--unit__nav .slick-slide {
      height: 90px; } }

.cat--unit__info {
  background-color: #fff;
  font-size: 16px;
  padding: 35px 48px;
  margin: 37px 0 75px; }
  .cat--unit__info h5 {
    text-transform: inherit;
    margin: 0 0 25px; }
  @media screen and (max-width: 1024px) {
    .cat--unit__info {
      padding: 20px 25px;
      font-size: 14px;
      margin: 30px 0 50px; }
      .cat--unit__info h5 {
        margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    .cat--unit__info {
      padding: 20px;
      margin: 25px 0 40px; }
      .cat--unit__info h5 {
        margin-bottom: 10px; } }

.sim h2 {
  margin-bottom: 20px; }

.similar__slider {
  display: block;
  width: 100%; }
  .similar__slider .slick-list {
    padding: 0 100px !important; }
  .similar__slider .slick-track {
    display: flex; }
  .similar__slider .slick-slide {
    height: auto; }
  .similar__slider .cat--inside__item {
    margin-bottom: 0;
    font-size: 16px; }
  .similar__slider.arrow .slick-arrow {
    width: 62px;
    height: 62px;
    background-color: rgba(0, 0, 0, 0.7); }
    .similar__slider.arrow .slick-arrow:after {
      background: url(../img/simArr.png) no-repeat center center;
      background-size: 15px; }
    .similar__slider.arrow .slick-arrow.slick-prev {
      left: 35px; }
    .similar__slider.arrow .slick-arrow.slick-next {
      right: 35px; }
  @media screen and (max-width: 1024px) {
    .similar__slider {
      padding: 0 5px; }
      .similar__slider .slick-list {
        padding: 0 !important; }
      .similar__slider .slick-slide {
        margin: 0 10px; }
      .similar__slider .cat--inside__item {
        font-size: 14px; }
      .similar__slider.arrow .slick-arrow {
        width: 50px;
        height: 50px; }
        .similar__slider.arrow .slick-arrow:after {
          background-size: 10px; }
        .similar__slider.arrow .slick-arrow.slick-prev {
          left: 20px; }
        .similar__slider.arrow .slick-arrow.slick-next {
          right: 20px; } }
  @media screen and (max-width: 768px) {
    .similar__slider.arrow .slick-arrow {
      width: 40px;
      height: 40px; }
    .similar__slider.cat--inside {
      margin: 0; } }
  @media screen and (max-width: 420px) {
    .similar__slider .cat--inside__item .cat--inside__img {
      height: 250px; }
    .similar__slider .cat--inside__item .cat--inside__info .stiker {
      right: 55px; } }

/* page сatalog */
/* page contacts */
.cts {
  display: flex;
  justify-content: space-between;
  margin: 0 -10px; }
  .cts ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .cts ul li {
      margin: 3px 0; }
  .cts .cts__item {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    justify-content: center;
    background-color: #eeeeee;
    padding: 22px;
    margin: 0 10px 20px; }
    .cts .cts__item .col--location {
      padding-left: 43px;
      margin: 0; }
      .cts .cts__item .col--location:after {
        font-size: 30px;
        top: 50%;
        transform: translateY(-50%); }
    .cts .cts__item .col--phone {
      padding-left: 37px; }
      .cts .cts__item .col--phone:after {
        font-size: 19px;
        top: 3px; }
    .cts .cts__item .col--email {
      padding-left: 37px; }
      .cts .cts__item .col--email:after {
        font-size: 16px;
        top: 3px; }
    .cts .cts__item h5 {
      margin: 0; }
    .cts .cts__item.user {
      position: relative;
      padding-left: 72px; }
      .cts .cts__item.user:after {
        content: '';
        width: 24px;
        height: 32px;
        background: url(../img/user.png) no-repeat center center;
        position: absolute;
        top: 50%;
        left: 25px;
        transform: translateY(-50%); }
  @media screen and (max-width: 1024px) {
    .cts .cts__item {
      padding: 15px; }
      .cts .cts__item .col--location {
        padding-left: 30px; }
        .cts .cts__item .col--location:after {
          font-size: 26px; }
      .cts .cts__item .col--phone,
      .cts .cts__item .col--email {
        padding-left: 30px; }
      .cts .cts__item .col--phone:after {
        font-size: 16px; }
      .cts .cts__item .col--email:after {
        font-size: 14px; }
      .cts .cts__item.user {
        padding-left: 50px; }
        .cts .cts__item.user:after {
          width: 22px;
          height: 28px;
          background-size: contain;
          left: 15px; } }
  @media screen and (max-width: 768px) {
    .cts {
      flex-wrap: wrap; }
      .cts .cts__item {
        width: 100%; }
        .cts .cts__item .col--location {
          padding-left: 25px; }
          .cts .cts__item .col--location:after {
            font-size: 24px; }
        .cts .cts__item .col--phone,
        .cts .cts__item .col--email {
          padding-left: 25px; }
        .cts .cts__item.user {
          padding-left: 40px; }
          .cts .cts__item.user:after {
            width: 20px;
            height: 23px;
            left: 10px; } }

.map {
  height: 330px;
  margin-bottom: 40px; }
  .map iframe {
    height: 100%;
    width: 100%; }
  @media screen and (max-width: 768px) {
    .map {
      height: 280px; } }

.cts__form .cts__input {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; }
  .cts__form .cts__input input {
    width: calc(33.33% - 20px);
    margin-left: 10px;
    margin-right: 10px; }

.cts__form .g-recaptcha {
  display: inline-block;
  vertical-align: top; }

.cts__form .btn {
  display: inline-block;
  vertical-align: top;
  width: 300px !important;
  margin: 0px 0 0 30px; }

@media screen and (max-width: 768px) {
  .cts__form .btn {
    width: auto !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    float: right; } }

@media screen and (max-width: 600px) {
  .cts__form .cts__input {
    margin: 0; }
    .cts__form .cts__input input {
      width: 100%;
      margin-left: 0;
      margin-right: 0; } }

@media screen and (max-width: 480px) {
  .cts__form {
    padding-bottom: 130px; }
    .cts__form .g-recaptcha {
      float: right; }
    .cts__form .btn {
      margin-top: 15px; } }

@media screen and (max-width: 340px) {
  .cts__form .g-recaptcha {
    transform: scale(0.9);
    transform-origin: 100% 50%; } }

/* page contacts */
/* page pagination */
.pagination {
  width: 100%;
  line-height: 1;
  margin-top: 25px; }
  .pagination ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center; }
    .pagination ul li {
      margin: 0 5px 5px; }
      .pagination ul li a {
        display: flex;
        width: 35px;
        height: 35px;
        align-items: center;
        justify-content: center;
        font-family: "robotobold";
        background-color: #f00000;
        color: #fff;
        position: relative; }
        .pagination ul li a:after {
          font-family: 'fontAwesome';
          font-size: 20px; }
        .pagination ul li a:hover {
          color: #fff;
          background-color: #c70000; }
      .pagination ul li.active a {
        background-color: #c70000; }
      .pagination ul li.prev a:after {
        content: '\f104'; }
      .pagination ul li.next a:after {
        content: '\f105'; }
  @media screen and (max-width: 1024px) {
    .pagination {
      margin-top: 15px; }
      .pagination ul li a {
        width: 30px;
        height: 30px; } }

/* page pagination */
/* modal */
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999; }
  .modal .modal__layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .modal .modal__block {
    width: 420px;
    background-color: #fff;
    position: absolute;
    padding: 20px;
    left: 0;
    right: 0;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%); }
    .modal .modal__block h4 {
      font-size: 20px;
      text-align: center;
      padding: 0 10px;
      margin: 0; }
  .modal form {
    display: block;
    padding-bottom: 130px;
    margin-top: 15px; }
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 50px;
      width: 100%;
      background-color: #eeeeee;
      font-family: "robotoregular";
      font-size: 16px;
      padding: 14px 18px;
      margin-bottom: 15px;
      box-shadow: none;
      border: none; }
      .modal form textarea::placeholder,
      .modal form input[type="text"]::placeholder,
      .modal form input[type="email"]::placeholder,
      .modal form input[type="search"]::placeholder,
      .modal form input[type="password"]::placeholder {
        color: #888888; }
    .modal form .edd-root {
      width: 100%;
      margin-bottom: 15px; }
      .modal form .edd-root .edd-head .edd-value {
        background-color: #eee;
        padding-top: 17px;
        padding-bottom: 17px;
        padding-left: 18px; }
    .modal form .g-recaptcha {
      float: right; }
    .modal form .btn {
      float: right;
      margin: 15px 0 0 0; }
  @media screen and (max-width: 1200px) {
    .modal .modal__block h4 {
      font-size: 18px; }
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 40px;
      font-size: 14px;
      padding: 10px 15px; }
    .modal form .edd-root .edd-head .edd-value {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 15px;
      padding-right: 40px; }
    .modal form .edd-root .edd-arrow {
      right: 15px; } }
  @media screen and (max-width: 1024px) {
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 35px;
      padding: 8px 15px; } }
  @media screen and (max-width: 768px) {
    .modal .modal__block h4 {
      font-size: 16px; } }
  @media screen and (max-width: 500px) {
    .modal .modal__block {
      width: auto;
      left: 10px;
      right: 10px; } }
  @media screen and (max-width: 380px) {
    .modal form .g-recaptcha {
      transform: scale(0.85);
      transform-origin: 100% 50%; } }

.call--btn {
  position: fixed;
  bottom: 100px;
  right: 40px;
  display: table;
  background-color: #ffcc00;
  font-family: 'FontAwesome';
  border-radius: 100%;
  text-decoration: none !important;
  width: 80px;
  height: 80px;
  text-align: center;
  padding: 23px 5px 5px 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  outline: none !important;
  z-index: 1; }
  .call--btn:hover {
    animation-name: phone;
    animation-iteration-count: 1;
    animation-duration: 0.7s; }
  .call--btn i {
    display: block;
    font-size: 35px;
    color: #fff; }
  .call--btn:after, .call--btn:before {
    content: '';
    width: 90%;
    height: 90%;
    background-color: transparent;
    border: 1px solid #ffcc00;
    position: absolute;
    top: 5%;
    left: 5%;
    border-radius: 100%;
    animation-iteration-count: infinite;
    animation-duration: 1.2s;
    animation-name: ring;
    outline: none; }
  .call--btn:after {
    animation-delay: 0.6s; }
  .call--btn:before {
    animation-delay: 0.8s;
    border: 1px solid #d9af04; }
  @media screen and (max-width: 1440px) {
    .call--btn {
      margin-right: 0;
      right: 35px;
      bottom: 75px; } }
  @media screen and (max-width: 991px) {
    .call--btn {
      width: 50px;
      height: 50px;
      padding-top: 15px;
      right: 15px;
      bottom: 80px; }
      .call--btn i {
        font-size: 24px; } }
  @media screen and (max-width: 768px) {
    .call--btn {
      bottom: 50px; } }

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0.1;
    transform: scale(1.7); } }

@keyframes phone {
  20% {
    transform: rotate(-30deg); }
  80% {
    transform: rotate(390deg); }
  100% {
    transform: rotate(360deg); } }

/* modal */
/* page content */
/* footer */
footer {
  position: relative;
  background: url(../img/img-18.jpg) no-repeat;
  background-size: cover;
  line-height: 1.2;
  padding-top: 50px;
  font-size: 16px;
  color: #2e2e2e; }
  footer .container {
    display: flex;
    justify-content: space-between; }
  footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    footer ul li {
      margin: 12px 0; }
      footer ul li.active a {
        color: #fe0000; }
  footer a {
    color: #2e2e2e; }
    footer a:focus {
      color: #2e2e2e; }
    footer a:hover {
      color: #fe0000; }
  footer h5 {
    color: #2f2f2f;
    margin: 0 0 25px; }
  footer .logo img {
    max-width: 100%; }
  footer .footer__item {
    padding-right: 25px; }
    footer .footer__item:last-of-type {
      padding-right: 0; }
    footer .footer__item.f--logo {
      text-align: center; }
      footer .footer__item.f--logo p {
        font-family: "robotobold";
        font-size: 14px;
        margin: 10px 0; }
    footer .footer__item.f--nav ul li a span {
      display: block; }
    footer .footer__item .socials {
      display: flex;
      justify-content: center;
      margin: 20px 0; }
      footer .footer__item .socials li {
        display: flex;
        align-items: center;
        position: relative;
        margin: 0 17px; }
        footer .footer__item .socials li:after {
          content: '';
          width: 1px;
          height: 22px;
          background-color: #676767;
          position: absolute;
          top: 50%;
          right: -18px;
          transform: translateY(-50%); }
        footer .footer__item .socials li:last-of-type:after {
          display: none; }
        footer .footer__item .socials li a {
          font-size: 20px; }
  footer .col--location,
  footer .col--phone,
  footer .col--email {
    padding-left: 0; }
    footer .col--location:after,
    footer .col--phone:after,
    footer .col--email:after {
      color: #4e4e4e;
      left: -20px; }
  footer .col--email:after {
    top: 3px; }
  footer .f--cts__list {
    margin-top: 25px; }
  footer .artmedia {
    display: flex;
    align-items: center;
    color: #fff; }
    footer .artmedia:hover {
      color: #fff; }
  footer .down {
    background-color: #c70000;
    color: #fff;
    position: relative;
    overflow: hidden;
    margin-top: 35px; }
    footer .down:before {
      content: '';
      width: 50%;
      background-color: #fe0000;
      position: absolute;
      left: 60%;
      top: 0;
      bottom: 0;
      transform: skew(-30deg);
      z-index: 0; }
    footer .down .container {
      display: flex;
      height: 66px;
      align-items: center;
      justify-content: space-between;
      position: relative; }
    footer .down p {
      margin: 0; }
  @media screen and (max-width: 1200px) {
    footer {
      font-size: 14px; }
      footer .col--location:after,
      footer .col--phone:after {
        top: 1px; }
      footer .col--email:after {
        top: 1px; } }
  @media screen and (max-width: 1024px) {
    footer {
      padding-top: 30px; }
      footer h5 {
        margin-bottom: 15px; }
      footer .down {
        margin-top: 20px; }
      footer .col--location,
      footer .col--phone,
      footer .col--email {
        padding-left: 20px; }
        footer .col--location:after,
        footer .col--phone:after,
        footer .col--email:after {
          left: 0; } }
  @media screen and (max-width: 768px) {
    footer .container {
      flex-wrap: wrap; }
    footer .footer__item.f--logo {
      width: 100%;
      padding-right: 0; }
    footer .down:before {
      left: 70%; } }
  @media screen and (max-width: 700px) {
    footer .down .container {
      height: auto;
      flex-direction: column;
      justify-content: center;
      justify-content: center;
      text-align: center;
      padding: 15px; }
    footer .artmedia {
      margin-top: 10px; } }
  @media screen and (max-width: 600px) {
    footer h5 {
      margin-bottom: 10px; }
    footer .footer__item {
      width: 100%;
      padding-right: 0;
      margin-bottom: 15px; }
      footer .footer__item.f--logo {
        margin-bottom: 0; }
    footer ul li {
      margin: 7px 0; } }

/* footer */
